import { Component, Input, Output, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"]
})
export class DashboardPageComponent implements OnInit {
  cols : number;
  chartCols: number;
  gridByBreakpoint = {
    xl: 4,
    lg: 4,
    md: 2,
    sm: 2,
    xs: 1
  }
  chartGridByBreakpoint = {
    xl: 5,
    lg: 5,
    md: 3,
    sm: 3,
    xs: 3
  }

  public href: string = this.router.url.replace( "/", "" ).split("/")[1];

  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This year', name: 'This Year' },
  ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this.KPICards=[
      {
        title: 'Activity',
        query: { 
          "measures":
          ["History.count"],
          "timeDimensions":[
            {
              "dimension":"History.createdat",
              "granularity":"day",
              "dateRange":this.selected
            }
          ],
          "order":{
            "History.count":"desc"
          },
          "dimensions":[
            "History.site"
          ],
          "filters":[
            {
              "member":"History.tenantkey",
              "operator":"equals",
              "values":[
                this.href
              ]
            }
          ]
      },
        
        progress: false,
        duration: 2.25,
      }
    ];
    this.HistorySite=new BehaviorSubject({
      measures: ["History.count"],
      timeDimensions: [
        {
          dimension: "History.createdat",
          //granularity: "day",
          dateRange: this.selected
        }
      ],
      order: {
        "History.count": "desc"
      },
      dimensions: [
        "History.site"
      ],
      filters: [
        {
          member: "History.tenantkey",
          operator: "equals",
          values: [
            this.href
          ]
        }
      ]
    });
    this.query= new BehaviorSubject({
      measures: ["History.count"],
      timeDimensions: [
        {
          dimension: "History.createdat",
          granularity: "day",
          dateRange: this.selected
        }
      ],
      order: {
        "History.count": "desc"
      },
      dimensions: [
        "History.identifier"
      ],
      filters: [
        {
          member: "History.tenantkey",
          operator: "equals",
          values: [
            this.href
          ]
        }
      ]
    });
    // this.cards;
    this.ngOnInit();
  }
  
  selectOption1(id: string) {
    this.KPICards=[
      {
        title: 'Activity',
        query: { 
          "measures":
          ["History.count"],
          "timeDimensions":[
            {
              "dimension":"History.createdat",
              "granularity":"day",
              "dateRange":this.selected
            }
          ],
          "order":{
            "History.count":"desc"
          },
          "dimensions":[
            "History.site"
          ],
          "filters":[
            {
              "member":"History.tenantkey",
              "operator":"equals",
              "values":[
                this.href
              ]
            },
            {
              "member": "History.site",
              "operator": "equals",
              "values": [
                id
              ]
            }
          ]
      },
        
        progress: false,
        duration: 2.25,
      }
    ];
    this.HistorySite=new BehaviorSubject({
      measures: ["History.count"],
      timeDimensions: [
        {
          dimension: "History.createdat",
          //granularity: "day",
          dateRange: this.selected
        }
      ],
      order: {
        "History.count": "desc"
      },
      dimensions: [
        "History.site"
      ],
      filters: [
        {
          member: "History.tenantkey",
          operator: "equals",
          values: [
            this.href
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.query= new BehaviorSubject({
      measures: ["History.count"],
      timeDimensions: [
        {
          dimension: "History.createdat",
          granularity: "day",
          dateRange: this.selected
        }
      ],
      order: {
        "History.count": "desc"
      },
      dimensions: [
        "History.identifier"
      ],
      filters: [
        {
          member: "History.tenantkey",
          operator: "equals",
          values: [
            this.href
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    // this.cards;
    this.ngOnInit();
  }
  constructor(private meta: Meta, private title: Title, private breakpointObserver: BreakpointObserver, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Dashboard with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Dashboard, Material UI' });

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.Large]) {
          this.cols = this.gridByBreakpoint.lg;
          this.chartCols = this.chartGridByBreakpoint.lg;
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          this.cols = this.gridByBreakpoint.xl;
          this.chartCols = this.chartGridByBreakpoint.xl;
        }
      }
    });
  }
  private query = new BehaviorSubject({
    measures: ["History.count"],
    timeDimensions: [
      {
        dimension: "History.createdat",
        granularity: "day",
        dateRange: this.selected
      }
    ],
    order: {
      "History.count": "desc"
    },
    dimensions: [
      "History.identifier"
    ],
    filters: [
      {
        member: "History.tenantkey",
        operator: "equals",
        values: [
          this.href
        ]
      }
    ]
  });
  public HistorySite=new BehaviorSubject({
    measures: ["History.count"],
    timeDimensions: [
      {
        dimension: "History.createdat",
       // granularity: "day",
        dateRange: this.selected
      }
    ],
    order: {
      "History.count": "desc"
    },
    dimensions: [
      "History.site"
    ],
    filters: [
      {
        member: "History.tenantkey",
        operator: "equals",
        values: [
          this.href
        ]
      }
    ]
  });
  changeDateRange = (value) => {
    this.query.next({
      ...this.query.value,
      timeDimensions: [{ dimension: "History.createdat", granularity: "day", dateRange: value }]
    });
  };

  
  public HistorySite1=new BehaviorSubject({
    "measures": [],
    "timeDimensions": [
      {
        "dimension": "Layers.createddate"
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "Layers.tenantkey",
        "operator": "equals",
        "values": [
          this.href
        ]
      },
      {
        "member": "Layers.category",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "member": "Layers.isdeleted",
        "operator": "equals",
        "values": [
          "false"
        ]
      }
    ],
    "dimensions": [
      "Layers.name"
    ]
  });

  @Input() dataSource = [];
  public _HistorySite1 = null;
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.HistorySite1=new BehaviorSubject({
      measures: ["History.count"],
      timeDimensions: [
        {
          dimension: "History.createdat",
         // granularity: "day",
          dateRange: this.selected
        }
      ],
      order: {
        "History.count": "desc"
      },
      dimensions: [
        "History.site"
      ],
      filters: [
        {
          member: "History.tenantkey",
          operator: "equals",
          values: [
            this.href
          ]
        }
      ]
    });
  }

  public cards = [];
  public KPICards = [
    {
      title: 'Activity',
      query: { 
        "measures":
        ["History.count"],
        "timeDimensions":[
          {
            "dimension":"History.createdat",
            "granularity":"day",
            "dateRange":this.selected
          }
        ],
        "order":{
          "History.count":"desc"
        },
        "dimensions":[
          "History.identifier"
        ],
        "filters":[
          {
            "member":"History.tenantkey",
            "operator":"equals",
            "values":[
              this.href
            ]
          }
        ]
    },
      
      progress: false,
      duration: 2.25,
    }
  ];
public queryData = null;
  ngOnInit() {
    this.query.subscribe(data => {
      this.cards[0] = {
        hasDatePick: true,
        title: 'Report',
        chart: "line", cols: 5, rows: 1,
        query: data
      };
    });
    this.HistorySite.subscribe(data => {
      this.queryData = data;
      this.cards[1] = {
        hasDatePick: true,
        title: 'Report',
        chart: "table", cols: 5, rows: 1,
        query: this.queryData
      };
    });
    this.HistorySite1.subscribe(data => {
      this._HistorySite1 = data;
      this.newCubeJs = new CubejsClient(
        {
          "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
          "options": {
              "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
          }
      });
      this.newCubeJs.load(this._HistorySite1).subscribe(
        resultSet => {
          this.dataSource = resultSet.rawData();
        },
        err => console.log("HTTP Error", err)
      );
    });
  }
  public newCubeJs;
}
