<div class="table-warp" style="background-color: #242533;padding: 0px 10px 0 10px;position: fixed;top: 0;width: 100%;z-index: 1;">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="insights-cards-wrapper">
          <div class="row narrow-row">
            <div class="col-2">
              <a [routerLink]="[redirectLinkActivity, href]">
                <div class="insights-card"> <i class="fas fa-exchange"></i>
                  <!-- <h5>Activity</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkAlerts, href]">
                <div class="insights-card"> <i class="fas fa-bell"></i>
                  <!-- <h5>Alerts</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkVehicles, href]">
                <div class="insights-card"> <i class="fas fa-car"></i>
                  <!-- <h5>Vehicles</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkDevices, href]">
                <div class="insights-card"> <i class="fas fa-laptop"></i>
                  <!-- <h5>Devices</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkPeople, href]">
                <div class="insights-card"> <i class="fas fa-user"></i>
                  <!-- <h5>People</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[acessBespoke, href]">
                <div class="insights-card active"> <i class="fas fa-star"></i>
                  <!-- <h5>People</h5> -->
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="table-warp" *ngIf="denied" style="position: absolute;left: 35%;color: #FFF;padding: 0px 0 10px 0;margin: 0 10px;margin-top: 65px;padding: 80px;">
    <div class="row narrow-row">
        <div class="col-12">To enable bespoke reporting email: bespoke@intelico.com</div>
    </div>
</div>
<div class="component-header d-flex align-items-center justify-content-between" *ngIf="access" style="margin-top: 50px;">
    <h4 style="visibility: hidden;"><i class="fas fa-car"></i>Vehicle Dashboard</h4>
    <div class="date-Picker-wrap d-flex align-items-center ">
      <select class="date-Picker" (change)="selectOption($event.target.value)">
        <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
      </select>
      <select class="date-Picker" (change)="selectOption1($event.target.value)">
        <option value="All">All</option>
        <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataset2">{{element['Layers.name']}}</option>
      </select>
    </div>
  </div>
<div class="table-warp" *ngIf="access">
    <div class="row narrow-row">
        <div class="col-12">
            <div class="kpi-wrap">
                <app-fuel-type-header [hybridElecData]="ElecHybridGasData" [volumnCount]="volumnCount" [upToEuro4Count]="upToEuro4Count" [euro4Plus]="euro4PlusCount" [upToEuro6Count]="upToEuro6Count" [euro6Plus]="euro6PlusCount" [dataSource]="volumnData"></app-fuel-type-header>
            </div>
        </div>

        <div class="col-6 fuel-type-main">
            <div class="fuel-type-g-main">
                <div class="fuel-type-g-title">Petrol Euro Standard</div>
                <app-euro-fuel-type [upToEuro4Count]="upToEuro4Count" [euro4Plus]="euro4PlusCount"></app-euro-fuel-type>
            </div>
        </div>
        <div class="col-6 fuel-type-d-main">
            <div class="fuel-type-d-g-main">
                <div class="fuel-type-d-g-title">Diesel Euro Standard</div>
                <app-euro-fuel-type6 [upToEuro6Count]="upToEuro6Count" [euro6Plus]="euro6PlusCount"></app-euro-fuel-type6>
            </div>
        </div>
        <div class="col-4" style="padding-left: 0;">
            <app-euro-fuel-euro4-count class="col-12" [volumnCount]="volumnCount" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeList"></app-euro-fuel-euro4-count>
        </div>
        <div class="col-4">
            <app-euro-fuel-euro5-count class="col-12" [volumnCount]="volumnCount" [dataValue]="dataSource"  [query]="queryVehicelsFuelTypeEuro5List"></app-euro-fuel-euro5-count>
        </div>
        <div class="col-4" style="padding-right: 0;">
            <app-euro-fuel-euro6-count class="col-12" [volumnCount]="volumnCount" [dataValue]="dataSource" [query]="queryVehicelsFuelTypeEuro6List"></app-euro-fuel-euro6-count>
        </div>
        
        <div class="col-12 make-main" style="display: none;">
            <div class="col-12 float-left">
                <div class="col-2 float-left make-title" style="max-width: 12%;text-align: right;">Make</div>
                <div class="col-1 float-left make-title-total" style="max-width: 5%;text-align: right;"> Count</div>
                <div class="col-9 float-left make-title-total" style="text-align: left;margin-left: 30px;">% of Total</div>
            </div>
        </div>
        <div class="col-12 make-graph-main">
            <div class="col-12">
                <app-vehicles-percentage class="col-12" [dataValue]="dataSource1" [query]="makePercentage"></app-vehicles-percentage>
            </div>
        </div>
    </div>
</div>
  