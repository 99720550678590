import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";

@Component({
  selector: 'app-volumn-fuel',
  templateUrl: './volumn-fuel.component.html',
  styleUrls: ['./volumn-fuel.component.scss']
})
export class VolumnFuelComponent implements OnInit {
    cols : number;
    chartCols: number;
  
    gridByBreakpoint = {
      xl: 4,
      lg: 4,
      md: 2,
      sm: 2,
      xs: 1
    }
    chartGridByBreakpoint = {
      xl: 5,
      lg: 5,
      md: 3,
      sm: 3,
      xs: 3
    }
    doughnutCols = 2;
    public href: string = this.router.url.replace( "/", "" ).split("/")[1];
    public apiUrl = "https://api.advancedstudio.co.uk/features/getAllOrganizations?ID=" + this.href;
    public report;
    public emissions;
    constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
      this.title.setTitle('Angular Dashboard with Material');
      this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
      this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
      }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }
      const xhttp = new XMLHttpRequest();
      var that = this;
      xhttp.onload = function() {
        if(this.status != 200){
          window.location.href = "/#/revoke";
        }
        let response = JSON.parse( this.response);
        if(response.success == 0){
          window.location.href = "/#/revoke";
        }else{
          that.repeat(response.data.emissions, response.data.report);
        }
      }
      console.log(that.report);
      xhttp.open("GET", this.apiUrl);
      xhttp.send();
  
      xhttp.onerror = function(){
        window.location.href = "/#/revoke";
      }
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
      }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }
    }
  
    repeat(emissions, report){
      this.emissions = emissions;
      this.report = report;
      setInterval(() => {
        this.ngOnInit();
      }, 30000);
    }
    
    dates: any[] = [
      { id: 'Today', name: 'Today' },
      { id: 'Yesterday', name: 'Yesterday' },
      { id: 'This week', name: 'This Week' },
      { id: 'Last week', name: 'Last Week' },
      { id: 'This month', name: 'This Month' },
      { id: 'Last month', name: 'Last Month' },
      { id: 'This Year', name: 'This Year' },
    ];
    selected: string = "Today";
    selectOption(id: string) {
      this.selected = id;

      this._totalVolumn = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this._euro4 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "petrol"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this._upToEuro4 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "petrol"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });

    this._euro4Plus = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "petrol"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 4",
            "euro 5",
            "euro 6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this._euro6 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "diesel"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ],
    });

    this._upToEuro6 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "diesel"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ],
    });


    this._euro6Plus = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "diesel"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ],
    });

    this._queryVehicelsFuelTypeList = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 4"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this._queryVehicelsFuelTypeEuro5List = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 5"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this._queryVehicelsFuelTypeEuro6List = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype"
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });


    this._makePercentage = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.make"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });

    this._ElecHybridGas = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    this.ngOnInit();
    }
    siteSelected: string = "All";
    selectOption1(id: string) {
      this.siteSelected = id;
      this._totalVolumn = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype"
        ],
        "filters": [
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "vehicle"
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
      this._euro4 = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehiclefueltype",
            "operator": "equals",
            "values": [
              "petrol"
            ]
          },
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 0",
              "euro 1",
              "euro 2",
              "euro 3",
              "euro 4",
              "euro 5",
              "euro 6",
              "euro6",
              "euro VI"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
      this._upToEuro4 = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehiclefueltype",
            "operator": "equals",
            "values": [
              "petrol"
            ]
          },
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 0",
              "euro 1",
              "euro 2",
              "euro 3"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
  
      this._euro4Plus = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehiclefueltype",
            "operator": "equals",
            "values": [
              "petrol"
            ]
          },
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 4",
              "euro 5",
              "euro 6",
              "euro6",
              "euro VI"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
      this._euro6 = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehiclefueltype",
            "operator": "equals",
            "values": [
              "diesel"
            ]
          },
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 0",
              "euro 1",
              "euro 2",
              "euro 3",
              "euro 4",
              "euro 5",
              "euro 6",
              "euro6",
              "euro VI"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ],
      });
  
      this._upToEuro6 = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehiclefueltype",
            "operator": "equals",
            "values": [
              "diesel"
            ]
          },
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 0",
              "euro 1",
              "euro 2",
              "euro 3",
              "euro 4",
              "euro 5"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ],
      });
  
  
      this._euro6Plus = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehiclefueltype",
            "operator": "equals",
            "values": [
              "diesel"
            ]
          },
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 6",
              "euro6",
              "euro VI"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ],
      });
  
      this._queryVehicelsFuelTypeList = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 4"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "vehicle"
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
      this._queryVehicelsFuelTypeEuro5List = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 5"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "vehicle"
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
      this._queryVehicelsFuelTypeEuro6List = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [
          "History.vehiclefueltype",
        ],
        "filters": [
          {
            "member": "History.vehicleeurostatus",
            "operator": "contains",
            "values": [
              "euro 6",
              "euro6",
              "euro VI"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "vehicle"
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });
  
  
      this._makePercentage = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [
          "History.make"
        ],
        "filters": [
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.identifiertype",
            "operator": "equals",
            "values": [
              "vehicle"
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "History.triggercondition",
            "operator": "contains",
            "values": [
              "enter"
            ]
          }
        ]
      });

    this._ElecHybridGas = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
      this.ngOnInit();
    }
    
    public _totalVolumn = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    public _euro4 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "petrol"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    public _upToEuro4 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "petrol"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });

    public _euro4Plus = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "petrol"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    public _euro6 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "diesel"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ],
    });

    public _upToEuro6 = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "diesel"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ],
    });


    public _euro6Plus = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehiclefueltype",
          "operator": "equals",
          "values": [
            "diesel"
          ]
        },
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ],
    });

    public _queryVehicelsFuelType = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": "This year"
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });

    public _queryVehicelsFuelTypeList = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 4"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    public _queryVehicelsFuelTypeEuro5List = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 5"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    public _queryVehicelsFuelTypeEuro6List = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype"
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });

    public _ElecHybridGas = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.vehiclefueltype",
      ],
      "filters": [
        {
          "member": "History.vehicleeurostatus",
          "operator": "contains",
          "values": [
            "euro 0",
            "euro 1",
            "euro 2",
            "euro 3",
            "euro 4",
            "euro 5",
            "euro 6",
            "euro6",
            "euro VI"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });

    public _makePercentage = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [
        "History.make"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "contains",
          "values": [
            "enter"
          ]
        }
      ]
    });
    public _query7 = new BehaviorSubject(
      {
        "measures": [],
        "timeDimensions": [
          {
            "dimension": "Layers.createddate"
          }
        ],
        "order": {},
        "filters": [
          {
            "member": "Layers.tenantkey",
            "operator": "equals",
            "values": [
              this.href
            ]
          },
          {
            "member": "Layers.category",
            "operator": "equals",
            "values": [
              "1"
            ]
          },
          {
            "member": "Layers.isdeleted",
            "operator": "equals",
            "values": [
              "false"
            ]
          }
        ],
        "dimensions": [
          "Layers.name"
        ]
      }
    );
    public query7;
    public query;
    public euro6;
    public dataSource;
    public dataSource1;
    public queryVehicelsFuelType;
    public queryVehicelsFuelTypeList;
    public queryVehicelsFuelTypeEuro5List;
    public queryVehicelsFuelTypeEuro6List;
    public makePercentage;
    public totalVolumn;
    public volumnData;
    public volumnCount = 0;
    public upToEuro4;
    public upToEuro4Data;
    public upToEuro4Count = 0;
    public euro4Plus;
    public euro4PlusData;
    public euro4PlusCount = 0;    
    public upToEuro6;
    public upToEuro6Data;
    public upToEuro6Count = 0;
    public euro6Plus;
    public euro6PlusData;
    public euro6PlusCount = 0;
    public ElecHybridGas;
    public ElecHybridGasData = [];
    public access = false;
    public denied = false;
    public urlLink;
    public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
    public redirectLinkActivity;
    public redirectLinkAlerts;
    public redirectLinkVehicles;
    public redirectLinkDevices;
    public redirectLinkPeople;
    public redirectLinkBespoke;
    public redirectLinkPartners;
    public acessBespoke;
    public fuelType
    public bespokeLink;
    ngOnInit() {
      this.urlLink = this.router.url;
        this.access = true;
    
      this.redirectLinkActivity = "/activity";
      this.redirectLinkAlerts = "/alerts";
      this.redirectLinkVehicles = "/vehicles";
      this.redirectLinkDevices = "/devices";
      this.redirectLinkPeople = "/people";
      this.redirectLinkBespoke = "/bespoke";
      this.bespokeLink = "/bespoke";
      this.acessBespoke = "/bespoke-w";
      this.fuelType = "/fuel-type";
      // this.currentUrl = "/insights-dashboard";
      this._totalVolumn.subscribe(query => {
        this.totalVolumn = query;
        this.cubejs.load(this.totalVolumn).subscribe(
          resultSet => {
            this.volumnData = [];
            this.volumnCount =0;
            this.volumnData = resultSet.tablePivot();
            for( var l in this.volumnData){
              if( this.volumnData[l]['History.vehiclefueltype'] != "" )
              this.volumnCount = this.volumnCount + this.volumnData[l]['History.count'];
            }
        });
      });
      this._upToEuro4.subscribe(query => {
        this.upToEuro4 = query;
        this.cubejs.load(this.upToEuro4).subscribe(
          resultSet => {
            this.upToEuro4Data = [];
            this.upToEuro4Count = 0;
            this.upToEuro4Data = resultSet.tablePivot();
            for( var m in this.upToEuro4Data){
              this.upToEuro4Count = this.upToEuro4Count + this.upToEuro4Data[m]['History.count'];
            }
        });
      });
      
      this._euro4Plus.subscribe(query => {
        this.euro4Plus = query;
        this.cubejs.load(this.euro4Plus).subscribe(
          resultSet => {
            this.euro4PlusData = [];
            this.euro4PlusCount = 0;
            this.euro4PlusData = resultSet.tablePivot();
            for( var m in this.euro4PlusData){
              this.euro4PlusCount = this.euro4PlusCount + this.euro4PlusData[m]['History.count'];
            }
        });
      });

      this._upToEuro6.subscribe(query => {
        this.upToEuro6 = query;
        this.cubejs.load(this.upToEuro6).subscribe(
          resultSet => {
            this.upToEuro6Data = [];
            this.upToEuro6Count = 0;
            this.upToEuro6Data = resultSet.tablePivot();
            for( var m in this.upToEuro6Data){
              this.upToEuro6Count = this.upToEuro6Count + this.upToEuro6Data[m]['History.count'];
            }
        });
      });

      this._euro6Plus.subscribe(query => {
        this.euro6Plus = query;
        this.cubejs.load(this.euro6Plus).subscribe(
          resultSet => {
            this.euro6PlusCount = 0;
            this.euro6PlusData = [];
            this.euro6PlusData = resultSet.tablePivot();
            for( var n in this.euro6PlusData){
              this.euro6PlusCount = this.euro6PlusCount + this.euro6PlusData[n]['History.count'];
            }
        });
      });

      this._ElecHybridGas.subscribe(query => {
        this.ElecHybridGas = query;
        this.cubejs.load(this.ElecHybridGas).subscribe(
          resultSet => {
            this.ElecHybridGasData = [];
            this.ElecHybridGasData = resultSet.tablePivot();
        });
      });
      
      
      this._euro4.subscribe(query => {
        this.query = query;
      });
      this._euro6.subscribe(query => {
        this.euro6 = query;
      });
      this._queryVehicelsFuelTypeList.subscribe(query => {
        this.queryVehicelsFuelTypeList = query;
      });
      this._queryVehicelsFuelTypeEuro5List.subscribe(query => {
        this.queryVehicelsFuelTypeEuro5List = query;
      });
      this._queryVehicelsFuelTypeEuro6List.subscribe(query => {
        this.queryVehicelsFuelTypeEuro6List = query;

      });
      this._makePercentage.subscribe(query => {
        this.makePercentage = query;
        this.cubejs.load(this.makePercentage).subscribe(
          resultSet => {
            this.dataSource1 = [];
            this.dataSource1 = resultSet.tablePivot();
            for(var index in this.dataSource1)
            { 
                if(this.dataSource1[index]['History.make'] == ""){
                  this.dataSource1[index]['History.make'] = "Unknown/Others";
                }
            }
          //   this.dataSource1.sort(function(a, b) {
          //     var textA = a['History.make'].toUpperCase();
          //     var textB = b['History.make'].toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // });
        });
      });
      
      this._queryVehicelsFuelType.subscribe(query => {
        this.queryVehicelsFuelType = query;
        this.cubejs.load(this.queryVehicelsFuelType).subscribe(
          resultSet => {
            this.dataSource = [];
            this.dataSource = resultSet.tablePivot();
            for(var index in this.dataSource)
            { 
                if(this.dataSource[index]['History.vehiclefueltype'] == ""){
                  this.dataSource.splice(index, 1);
                }
            }
            this.dataSource.sort(function(a, b) {
              var textA = a['History.vehiclefueltype'].toUpperCase();
              var textB = b['History.vehiclefueltype'].toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        });
      });
      if( this.siteSelected == "All" ){
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
            "options": {
                "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this._query7.subscribe(query7 => {
          this.query7 = query7;
          this.newCubeJs.load(this.query7).subscribe(
            resultSet => {
              this.dataset2 = resultSet.rawData();
              this.dataset2.sort(this.dynamicSort("Layers.name"));
            },
            err => console.log("HTTP Error", err)
          );
        });
      }
    }
    public selectedSite;
    public newCubeJs;
    public dataset2;
    public newDataSource = [];
    dynamicSort(property) {
      var sortOrder = 1;
  
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
  
      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }        
      }
    }
  }
  