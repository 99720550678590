import { Component, OnInit,ViewChild, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { stringify } from "@angular/compiler/src/util";

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import { Moment} from 'moment';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-export-csv',
  templateUrl: './export-csv.component.html',
  styleUrls: ['./export-csv.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
@Injectable()
export class ExportCsvComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public apiUrl = "https://api.advancedstudio.co.uk/features/getAllOrganizations?ID=" + this.href;
  public report;
  public emissions;
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
    const xhttp = new XMLHttpRequest();
    var that = this;
    xhttp.onload = function() {
      if(this.status != 200){
        window.location.href = "/#/revoke";
      }
      let response = JSON.parse( this.response);
      if(response.success == 0){
        window.location.href = "/#/revoke";
      }else{
        that.repeat(response.data.emissions, response.data.report);
      }
    }
    console.log(that.report);
    xhttp.open("GET", this.apiUrl);
    xhttp.send();

    xhttp.onerror = function(){
      window.location.href = "/#/revoke";
    }
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // some code..
    }else{
      if ( window.location == window.parent.location ) {
        window.location.href = "/#/revoke"
      }
    }
  }

  repeat(emissions, report){
    this.emissions = emissions;
    this.report = report;
    setInterval(() => {
      this.ngOnInit();
    }, 30000);
  }
  
  public limit = 10;
  public limit1 = 10;
  public limit2 = 10;
  public limit3 = 10;
  public limit4 = 10;
  public limit5 = 10;
  public limit6 = 10;
  public page = 0;
  public page1 = 0;
  public page2 = 0;
  public page3 = 0;
  public page4 = 0;
  public page5 = 0;
  public page6 = 0;
  public query = null;
  public queryPie = null;
  public query0 = null;
  public query1 = null;
  public query2 = null;
  public query3 = null;
  public query4 = null;
  public query5 = null;
  public query6 = null;
  public query7 = null;
  public alertsCards = null;
  query7_0Data = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  cols : number;
  chartCols: number;
  gridByBreakpoint = {
    xl: 4,
    lg: 4,
    md: 2,
    sm: 2,
    xs: 1
  }
  chartGridByBreakpoint = {
    xl: 5,
    lg: 5,
    md: 3,
    sm: 3,
    xs: 3
  }

  months = [
    { id: "2022-01", name: "Jan 2022" },
    { id: "2022-02", name: "Feb 2022"},
    { id: "2022-03", name: "Mar 2022"},
    { id: "2022-04", name: "Apr 2022"},
    { id: "2022-05", name: "May 2022"},
    { id: "2022-06", name: "Jun 2022"},
    { id: "2022-07", name: "Jul 2022"},
    { id: "2022-08", name: "Aug 2022"},
    { id: "2022-09", name: "Sep 2022"},
    { id: "2022-10", name: "Oct 2022"},
    { id: "2022-11", name: "Nov 2022"},
    { id: "2022-12", name: "Dec 2022"},
    { id: "2023-01", name: "Jan 2023" },
    { id: "2023-02", name: "Feb 2023"},
    { id: "2023-03", name: "Mar 2023"},
    { id: "2023-04", name: "Apr 2023"},
    { id: "2023-05", name: "May 2023"},
    { id: "2023-06", name: "Jun 2023"},
    { id: "2023-07", name: "Jul 2023"},
    { id: "2023-08", name: "Aug 2023"},
    { id: "2023-09", name: "Sep 2023"},
    { id: "2023-10", name: "Oct 2023"},
    { id: "2023-11", name: "Nov 2023"},
    { id: "2023-12", name: "Dec 2023"},

  ]

  year;
  month;
  date = new FormControl(moment());
  public picker = new FormControl();
  public _query;
  public firstDay;
  public lastDay;
  public jsonData = [];
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.month = normalizedMonth.month()+1;
    this.year = normalizedMonth.year();
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.getFirstDay(moment(ctrlValue).format('YYYY-MM-DD'));
  }

  getFirstDay(selectedDate){
    var date = new Date(selectedDate);
    this.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.firstDay = moment(this.firstDay).format('YYYY-MM-DD');
    this.lastDay = moment(this.lastDay).format('YYYY-MM-DD');
  }

download(){
  this.downloadFile(this.jsonData, this.groupName+this.year+"-"+this.month);
}


downloadFile(data, filename='data') {
  let csvData = this.ConvertToCSV(data, ['Identifier','Source', 'Time']);
  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
   let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
   let str = '';
   let row = '';
   let rowData = this.groupName + ",";
  //  rowData += this.year + "/" + this.month;
   rowData += this.month;
   str += rowData + '\r\n';

   for (let index in headerList) {
       row += headerList[index] + ',';
   }
  //  
   row = row.slice(0, -1);
   str += row + '\r\n';
   for (let i = 0; i < array.length; i++) {
       let line = '';
       for (let index in headerList) {
          let head = headerList[index];
          if( line == '' ){
           line += array[i][head];
          }else{
            line += ',' + array[i][head];
           }
       }
       
      str += line + '\r\n';
   }
   return str;
}

  @ViewChild('picker') datePickerElement = MatDatepicker;

  selectOption1(id: string, event:any) {
    this.siteSelected = id;
    for( let i = 0; i < event.target['options'].length; i++ ){
      var option = event.target['options'][i];
      if( this.siteSelected == option.value ){
        this.groupName = option.text;
      }
    }
  }

  selectOption(id: string) {
    this.month = id;
    this.getFirstDay(moment(id).format('YYYY-MM-DD'));
  }

  runScript(){
    this.loading = true;
    this._query = new BehaviorSubject({
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": [
            this.firstDay,
            this.lastDay
          ]
        }
      ],
      "order": {
        "History.createdat": "asc"
      },
      "dimensions": [
        "History.source",
        "History.identifier",
        "History.createdat"
      ],
      "filters": [
        {
          "member": "History.groupid",
          "operator": "contains",
          "values": [
            this.siteSelected
          ]
        }
      ]
    });
    this._query.subscribe(query => {
      this.query = query;
      this.cubejs.load(this.query).subscribe(
        resultSet => {
          var groupList = resultSet.rawData();
          this.groupList = [];
          this.jsonData = [];
          for( var i = 0; i < groupList.length; i++ ){
            var groups = groupList[i];
            this.groupList.push( {"History.identifier": groups['History.identifier'], "History.source": groups['History.source'], "History.createdat": moment(groups['History.createdat']).format('DD/MM/YYYY HH:mm:ss')} )
            this.jsonData.push(
              {
                Identifier: groups['History.identifier'],
                Source: groups['History.source'],
                Time: moment(groups['History.createdat']).format('DD/MM/YYYY HH:mm:ss')
              }
            )
          }
          this.loading = false;
        },
        err => {console.log("HTTP Error", err);this.loading = false;}
      );
    });
    this.ngOnInit();
  }

  public _groups = new BehaviorSubject({
      "measures": [],
      "timeDimensions": [],
      "order": {
        "GroupIdentifiers.count": "desc"
      },
      "dimensions": [
        "GroupIdentifiers.groupid",
        "Groups.name"
      ],
      "filters": [
        {
          "member": "Groups.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        }
      ]
    });

  public selectedSite;
  public newCubeJs;
  public bespokeLink;
  public queryVehicles;
  public queryDevice;
  public queryPeople;
  public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public acessBespoke;
  public currentUrl;
  public loading = false;
  public groupData;
  public dataSource = [];
  public groupList = [];
  public siteSelected;
  public access;
  public denied;
  public groupName;
  ngOnInit(): void {

    this.urlLink = this.router.url;
      this.access = true;

    this.selectedSite = this.siteSelected;
    if( !this.siteSelected ){
      this._groups.subscribe(groupData => {
        this.groupData = groupData;
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
            "options": {
                "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this.newCubeJs.load(this.groupData).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
            for( var i = 0; i < this.dataSource.length; i++ ){
              var grouplist = this.dataSource[i];
              if( i == 0 ){
                this.siteSelected = grouplist['GroupIdentifiers.groupid'];
                this.groupName = grouplist['Groups.name'];
              }
            }
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
}
