import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { stringify } from "@angular/compiler/src/util";

@Component({
  selector: 'app-call-alerts',
  templateUrl: './call-alerts.component.html',
  styleUrls: ['./call-alerts.component.scss']
})
export class CallAlertsComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
    }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public limit = 10;
  public limit1 = 10;
  public limit2 = 10;
  public limit3 = 10;
  public limit4 = 10;
  public limit5 = 10;
  public limit6 = 10;
  public page = 0;
  public page1 = 0;
  public page2 = 0;
  public page3 = 0;
  public page4 = 0;
  public page5 = 0;
  public page6 = 0;
  public query = null;
  public queryPie = null;
  public query0 = null;
  public query1 = null;
  public query2 = null;
  public query3 = null;
  public query4 = null;
  public query5 = null;
  public query6 = null;
  public query7 = null;
  public alertsCards = null;
  query7_0Data = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  cols : number;
  chartCols: number;
  gridByBreakpoint = {
    xl: 4,
    lg: 4,
    md: 2,
    sm: 2,
    xs: 1
  }
  chartGridByBreakpoint = {
    xl: 5,
    lg: 5,
    md: 3,
    sm: 3,
    xs: 3
  }
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This year', name: 'This Year' },
  ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this._query = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.priority"
      ],
      "filters":[
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        }
      ]
    });
    this._queryPie = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.priority"
      ],
      "filters":[
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        }
      ]
    });
    this._query1 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.identifiertype"
      ],
      "filters":[
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        }
      ]
    });
    this._query2 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "limit": 10,
      "dimensions": [
        "Tasks.identifier",
        "Tasks.identifiertype"
      ],
      "filters":[
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        }
      ]
    });
   
    this._query3 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.actionreason"
      ],
      "filters": [
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        }
      ]
    });
  
    this._query4 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.resolvedon",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.resolvedbyuseremail"
      ],
      "filters": [
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        },
        {
          "member": "Tasks.status",
          "operator": "equals",
          "values": [
            "200"
          ]
        }
      ]
    });
    this._query5 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "limit": 10,
      "dimensions": [
        "Tasks.source"
      ],
      "filters": [
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        }
      ]
    });
    this._query6 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.resolvedreason"
      ],
      "filters": [
        {
          "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
        },
        {
          "member": "Tasks.status",
          "operator": "equals",
          "values": [
            "200"
          ]
        }
      ]
    });
    this._query0 = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange":this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.type"
      ],
      "filters":[
        {
        "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
        }
      ]
    });
    
  this._alertsCards = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority",
      "Tasks.status"
    ],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ]
  });

  this._queryVehicles = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
this._queryDevice = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
    this._queryPeople = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [],
        "filters": [
          {
            "member": "Tasks.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "Tasks.peopleid",
            "operator": "set",
            "values": []
          }
        ]
      });
    this.ngOnInit();
  }
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }else{
      this._query = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.priority"
        ],
        "filters":[
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._queryPie = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.priority"
        ],
        "filters":[
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._query1 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.identifiertype"
        ],
        "filters":[
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._query2 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "limit": 10,
        "dimensions": [
          "Tasks.identifier",
          "Tasks.identifiertype"
        ],
        "filters":[
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
    
      this._query3 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.actionreason"
        ],
        "filters": [
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
    
      this._query4 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.resolvedon",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.resolvedbyuseremail"
        ],
        "filters": [
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          },
          {
            "member": "Tasks.status",
            "operator": "equals",
            "values": [
              "200"
            ]
          }
        ]
      });
      this._query5 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "limit": 10,
        "dimensions": [
          "Tasks.source"
        ],
        "filters": [
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._query6 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.resolvedreason"
        ],
        "filters": [
          {
            "member":"Tasks.tenantkey",
            "operator":"contains",
            "values":[
              this.href
            ]
          },
          {
            "member": "Tasks.status",
            "operator": "equals",
            "values": [
              "200"
            ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._query0 = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange":this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.type"
        ],
        "filters":[
          {
          "member":"Tasks.tenantkey",
              "operator":"contains",
              "values":[
                this.href
              ]
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this._alertsCards = new BehaviorSubject({
          "measures": [
            "Tasks.count"
          ],
          "timeDimensions": [
            {
              "dimension": "Tasks.createdat",
              "dateRange": this.selected
            }
          ],
          "order": {
            "Tasks.count": "desc"
          },
          "dimensions": [
            "Tasks.priority",
            "Tasks.status"
          ],
          "filters": [
            {
              "member": "Tasks.tenantkey",
              "operator": "contains",
              "values": [
                this.href
              ]
            },
            {
              "member": "Tasks.site",
              "operator": "equals",
              "values": [
                id
              ]
            }
          ]
        });

  this._queryVehicles = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          id
        ]
      }
    ]
  });
this._queryDevice = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          id
        ]
      }
    ]
  });
    this._queryPeople = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [],
        "filters": [
          {
            "member": "Tasks.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "Tasks.peopleid",
            "operator": "set",
            "values": []
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
    }
    this.ngOnInit();
    
  }
  public _query = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority"
    ],
    "filters":[
      {
      "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      }
    ]
  });
  public _queryPie = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority"
    ],
    "filters":[
      {
      "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      }
    ]
  });
  public _query0 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.type"
    ],
    "filters":[
      {
      "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      }
    ]
  });
  public _query1 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.identifiertype"
    ],
    "filters":[
      {
        "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      }
    ]
  });
  public _query2 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "limit": 10,
    "dimensions": [
      "Tasks.identifier",
      "Tasks.identifiertype"
    ],
    "filters":[
      {
        "member":"Tasks.tenantkey",
        "operator":"contains",
        "values":[
          this.href
        ]
      }
    ]
  });
 
  public _query3 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.actionreason"
    ],
    "filters": [
      {
        "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      }
    ]
  });

  public _query4 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.resolvedon",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.resolvedbyuseremail"
    ],
    "filters": [
      {
        "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      },
      {
        "member": "Tasks.status",
        "operator": "equals",
        "values": [
          "200"
        ]
      }
    ]
  });


  public _query5 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "limit": 10,
    "dimensions": [
      "Tasks.source"
    ],
    "filters": [
      {
        "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      }
    ]
  });

  public _query6 = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange":this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.resolvedreason"
    ],
    "filters": [
      {
        "member":"Tasks.tenantkey",
          "operator":"contains",
          "values":[
            this.href
          ]
      },
      {
        "member": "Tasks.status",
        "operator": "equals",
        "values": [
          "200"
        ]
      }
    ]
  });
  
  public _query7 = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "Layers.createddate"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "dimensions": [
        "Layers.name"
      ]
    }
  );
  
  public _alertsCards = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority",
      "Tasks.status"
    ],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ]
  });
  public _queryVehicles = new BehaviorSubject( {
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
    });
  public _queryDevice = new BehaviorSubject( {
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        }
      ]
    });
    public _queryPeople = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        }
      ]
    });

  public dataSource = [];
  public selectedSite;
  public newCubeJs;
  public bespokeLink;
  public queryVehicles;
  public queryDevice;
  public queryPeople;
  public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public acessBespoke;
  public currentUrl;
  ngOnInit(): void {
    this.urlLink = this.router.url;
        this.redirectLinkActivity = "/activity";
        this.redirectLinkAlerts = "/alerts";
        this.redirectLinkVehicles = "/vehicles";
        this.redirectLinkDevices = "/devices";
        this.redirectLinkPeople = "/people";
        this.redirectLinkBespoke = "/bespoke";
        this.bespokeLink = "/bespoke";
        this.acessBespoke = "/bespoke-w";
        // this.currentUrl = "/insights-dashboard";
      
      this.bespokeLink = "/bespoke";
    
    this.selectedSite = this.siteSelected;
    this._query.subscribe(query => {
      this.query = query;
    });
    this._queryPie.subscribe(query => {
      this.queryPie = query;
    });
    this._query0.subscribe(query0 => {
      this.query0 = query0;
    });
    this._query1.subscribe(query1 => {
      this.query1 = query1;
    });
    this._query2.subscribe(query2 => {
      this.query2 = query2;
    });
    this._query3.subscribe(query3 => {
      this.query3 = query3;
    });
    this._query4.subscribe(query4 => {
      this.query4 = query4;
    });
    this._query5.subscribe(query5 => {
      this.query5 = query5;
    });
    this._query6.subscribe(query6 => {
      this.query6 = query6;
    });
    this._alertsCards.subscribe(alertsCards => {
      this.alertsCards = alertsCards;
    });
    this._queryVehicles.subscribe(queryVehicles => {
      this.queryVehicles = queryVehicles;
    });
    this._queryDevice.subscribe(queryDevice => {
      this.queryDevice = queryDevice;
    });
    this._queryPeople.subscribe(queryPeople => {
      this.queryPeople = queryPeople;
    });
    
    if( this.siteSelected == "All" ){
      this.newCubeJs = new CubejsClient(
        {
          "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
          "options": {
              "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
          }
      });
      this._query7.subscribe(query7 => {
        this.query7 = query7;
        this.newCubeJs.load(this.query7).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
            this.dataSource.sort(this.dynamicSort("Layers.name"));
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

