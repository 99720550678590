import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-alerts-card-mobile',
  templateUrl: './alerts-card-mobile.component.html',
  styleUrls: ['./alerts-card-mobile.component.scss'],
  styles: ['body { background-color: #051F2C !important; }']
})
export class AlertsCardMobileComponent {
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.newCount = 0;
        this.normalCount = 0;
        this.highCount = 0;
        this.criticalCount = 0;
        this.resolvedCount = 0;
        this.totalCount = 0;
        let normalData = false;
        let highData = false;
        let criticalData = false;
        this.dataSource = resultSet.tablePivot();
        this.newDataSource = [];
        this.newDataSource.push({"Tasks.priority": "Total", "Tasks.count": 0});
        this.newDataSource.push({"Tasks.priority": "New", "Tasks.count": 0});
        this.newDataSource.push({"Tasks.priority": "Resolved", "Tasks.count": 0});
        for(var index in this.dataSource)
        { 
            if(this.dataSource[index]['Tasks.priority'] == 200){
              normalData = true;
            }
            if(this.dataSource[index]['Tasks.priority'] == 300){
              highData = true;
            }
            if(this.dataSource[index]['Tasks.priority'] == 400){
              criticalData = true;
            }
        }
        
        if( normalData == false ){
          this.newDataSource.push({"Tasks.priority": "Normal", "Tasks.count": 0});
        }
        if( highData == false ){
          this.newDataSource.push({"Tasks.priority": "High", "Tasks.count": 0});
        }
        if( criticalData == false ){
          this.newDataSource.push({"Tasks.priority": "Critical", "Tasks.count": 0});
        }
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          if( ndata['Tasks.priority'] == "200"){
            this.newDataSource.push({"Tasks.priority": "Normal", "Tasks.status": ndata['Tasks.status'], "Tasks.count": ndata['Tasks.count']});
          }
          if( ndata['Tasks.priority'] == "300"){
            this.newDataSource.push({"Tasks.priority": "High", "Tasks.status": ndata['Tasks.status'], "Tasks.count": ndata['Tasks.count']});
          }
          if( ndata['Tasks.priority'] == "400"){
            if( ndata['Tasks.status'] ){
              this.newDataSource.push({"Tasks.priority": "Critical", "Tasks.status": ndata['Tasks.status'], "Tasks.count": ndata['Tasks.count']});
            }else{
              this.newDataSource.push({"Tasks.priority": "Critical", "Tasks.status": 0, "Tasks.count": ndata['Tasks.count']});
            }
          }
        }
        let totalDataCritical = false;
        let totalDataHigh = false;
        let totalDataNormal = false;
        this.newDataSource.forEach((element,index)=>{
            if( element["Tasks.status"] == "100" ){
              this.newCount = element['Tasks.count'] + this.newCount;
            }
            if( element["Tasks.status"] == "200" ){
              this.resolvedCount = element['Tasks.count'] + this.resolvedCount;
            }
            if( element["Tasks.priority"] == "Normal" ){
              this.normalCount = element['Tasks.count'] + this.normalCount;
              element['Tasks.count'] = this.normalCount;
            }
            if( element["Tasks.priority"] == "High" ){
              this.highCount = element['Tasks.count'] + this.highCount;
            }
            if( element["Tasks.priority"] == "Critical" ){
              this.criticalCount = element['Tasks.count'] + this.criticalCount;
                element['Tasks.count'] = this.criticalCount;
            }
            this.totalCount = this.totalCount + this.newCount + this.resolvedCount + this.normalCount + this.highCount + this.criticalCount;
        });
        this.newDataSource.forEach((element,index)=>{
          if(element['Tasks.priority'] == "New"){
            element['Tasks.count'] = this.newCount;
          }
          if(element['Tasks.priority'] == "Total"){
            element['Tasks.count'] = this.totalCount;
          }
          if(element['Tasks.priority'] == "Resolved"){
            element['Tasks.count'] = this.resolvedCount;
          }
          if(element['Tasks.priority'] == "Normal"){
            element['Tasks.count'] = this.normalCount;
          }
          if(element['Tasks.priority'] == "High"){
            element['Tasks.count'] = this.highCount;
          }
          if(element['Tasks.priority'] == "Critical"){
            element['Tasks.count'] = this.criticalCount;
          }
        });
        this.newDataSource.forEach((element,index)=>{
          if(element['Tasks.priority'] == "Critical"){
            if( totalDataCritical == true ){
              this.newDataSource.splice(index,1)
            }
            totalDataCritical = true;
          }
        });
        this.newDataSource.forEach((element,index)=>{
          if(element['Tasks.priority'] == "Normal"){
            if( totalDataNormal == true ){
              this.newDataSource.splice(index,1)
            }
            totalDataNormal = true;
          }
        });
        this.newDataSource.forEach((element,index)=>{
          if(element['Tasks.priority'] == "High"){
            if( totalDataHigh == true ){
              this.newDataSource.splice(index,1)
            }
            totalDataHigh = true;
          }
        });
        this.newDataSource.forEach((element,index)=>{
          if(element['Tasks.priority'] == "Total"){
            element['Tasks.count'] = this.highCount + this.normalCount + this.criticalCount;
          }
        });
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryVehicles(queryVehicles: object) {
    this.loading = true;
    this.cubejs.load(queryVehicles).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.vehiclesCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryDevice(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.devicesCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() set queryPeople(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          this.peopleCount = ndata['Tasks.count'];
        }
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  newCount=0;
  resolvedCount=0;
  normalCount=0;
  highCount=0;
  criticalCount=0;
  vehiclesCount = 0;
  devicesCount = 0;
  peopleCount = 0;
  newDataSource = [];
  totalCount = 0;
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
