<div class="table-warp" *ngIf="denied" style="color: #FFF;padding: 0px 0 10px 0;margin: 0 10px;margin-top: 65px;padding: 80px;">
  <div class="row narrow-row">
      <div class="col-12">To enable bespoke reporting email: bespoke@intelico.com</div>
  </div>
</div>

<div class="component-header d-flex align-items-center justify-content-between" *ngIf="access">
  <h4><i class="fas fa-list"></i>Group Report</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <!-- <mat-form-field appearance="fill">
      <input matInput [matDatepicker]="dp3" [formControl]="date" disabled>
      <mat-datepicker-toggle matSuffix [for]="dp3" style="color: #FFF;"></mat-datepicker-toggle>
      <mat-datepicker #dp3 disabled="false" 
          startView="multi-year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp3)"
          panelClass="example-month-picker">
      </mat-datepicker>
    </mat-form-field> -->
    <select class="date-Picker" (change)="selectOption($event.target.value)" style="height: 42px;">
      <option value="">Select Month</option>
      <option value="{{element.id}}" *ngFor="let element of months">{{element.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value, $event)" style="height: 42px;">
      <option value="{{element['GroupIdentifiers.groupid']}}" [ngValue]="i > 0 ? element['GroupIdentifiers.groupid'] : null" *ngFor="let element of dataSource;let i = index" [attr.selected]="element['GroupIdentifiers.groupid'] == siteSelected ? 'selected' : null" >{{element['Groups.name']}}</option>
    </select>
    <button class="btn" (click)="runScript()">
      <div class="export-csv example-loading-shade"
           *ngIf="loading" style="width: 30px;height: 40px;">
        <mat-spinner></mat-spinner>
      </div>
      <p *ngIf="loading == false">Run</p>
    </button>
  </div>
</div>

<div class="table-warp"  *ngIf="access">
  <div class="row narrow-row">
    <div class="col-12">
      <div class="custom-table">
        <div class="table-header-row">
          <div class="cell-wrap flex-fill" style="max-width: 45%;">Identifier</div>
          <div class="cell-wrap text-right" style="width: 25%;text-align: center;">Source</div>
          <div class="cell-wrap text-right" style="width: 30%;">Time</div>
        </div>
        <div class="table-inner-wrapper">
          <div class="table-row-wrapper" *ngFor="let element of groupList">
            <div class="table-row">
              <div class="cell-wrap flex-fill" style="max-width: 50%;">{{element['History.identifier']}}</div>
              <div class="cell-wrap text-right" style="width: 18%;text-align: center;">{{element['History.source']}}</div>
              <div class="cell-wrap text-right" style="width: 33%;">{{element['History.createdat']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-warp" *ngIf="access">
  <div class="row narrow-row">
    <div class="col-12">
      <button class="btn" (click)="download()" style="float: right;">
        Export csv
      </button>
    </div>
  </div>
</div>
