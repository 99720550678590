import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";


@Component({
  selector: 'app-people-mobile',
  templateUrl: './people-mobile.component.html',
  styleUrls: ['./people-mobile.component.scss']
})
export class PeopleMobileComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    document.body.style.backgroundColor = "#051F2C";
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // some code..
    }else{
      if ( window.location == window.parent.location ) {
        window.location.href = "/#/revoke"
      }
    }
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public queryVehicelsColour = null;
  public queryMake = null;
  public querySites = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  reloadIframe(){
    window.parent.location.reload();
    window.opener.document.location.reload ()
  }
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This Year', name: 'This Year' },
  ];
  selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
    this.ngOnInit();
  }
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }
}
  public _queryVehicelsColour = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.make"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
 
  public _querySites = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "Layers.createddate"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "dimensions": [
        "Layers.name"
      ]
    }
  );
  
  public dataSource = [];
  public newDataSource = [];
  public newDataSourceTax = [];
  name: string;
  public newCubeJs;
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public currentUrl;
  public acessBespoke;
  public access = "accessed";
  vehicelsTypeData: Array<{name: string}> = []; 
  ngOnInit(): void {
    this.urlLink = this.router.url;
      
        this.redirectLinkActivity = "/activity-mobile";
        this.redirectLinkAlerts = "/alerts-mobile";
        this.redirectLinkVehicles = "/vehicles-mobile";
        this.redirectLinkDevices = "/devices-mobile";
        this.redirectLinkPeople = "/people-mobile";
        this.redirectLinkBespoke = "/bespoke-mobile";
        this.acessBespoke = "/bespoke-mobile";
        // this.currentUrl = "/insights-dashboard";
      
    if( this.siteSelected == "All" ){
      this._querySites.subscribe(querySite => {
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
            "options": {
                "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this.querySites = querySite;
        this.newCubeJs.load(this.querySites).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
            this.dataSource.sort(this.dynamicSort("Layers.name"));
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

