<!--<div class="table-warp" style="background-color: #242533;padding: 0px 10px 0 10px;position: fixed;top: 0;width: 100%;z-index: 1;">
  <div class="row narrow-row">
    <div class="col-12">
      <div class="insights-cards-wrapper">
        <div class="row narrow-row">
          <div class="col-2">
            <a [routerLink]="[redirectLinkActivity, href]">
              <div class="insights-card"> <i class="fas fa-exchange"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkAlerts, href]">
              <div class="insights-card"> <i class="fas fa-bell"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkVehicles, href]">
              <div class="insights-card"> <i class="fas fa-car"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkDevices, href]">
              <div class="insights-card"> <i class="fas fa-laptop"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkPeople, href]">
              <div class="insights-card active"> <i class="fas fa-user"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[acessBespoke, href]">
              <div class="insights-card"> <i class="fas fa-star"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-users"></i>People Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
    </select>
  </div>
</div>

  
  
  <div class="table-warp">
    <div class="col-6" style="margin-top: 20px;float: left;">
      <div class="custom-table">
        <div class="table-header-row">
          <div class="cell-wrap flex-fill">People</div>
            <div class="cell-wrap text-right" style="width: 80px;">Count</div>
        </div>
        <div class="table-inner-wrapper">
          <div class="table-row-wrapper" *ngFor="let element of peopleListing">
            <div class="table-row">
              <div class="cell-wrap flex-fill">People</div>
              <div class="cell-wrap text-right" style="width: 80px;">{{element['Peoples.count']}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-table">
        <div class="table-header-row">
          <div class="cell-wrap flex-fill">Condition</div>
            <div class="cell-wrap text-right" style="width: 80px;">Count</div>
        </div>
        <div class="table-inner-wrapper">
          <div class="table-row-wrapper" *ngFor="let element of peopleEnterExitListing">
            <div class="table-row">
              <div class="cell-wrap flex-fill">{{element['History.triggercondition']}}</div>
              <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-people-messaging  [href]="href" [dateRange]="selected" [selectedSite]="siteSelected"></app-people-messaging> -->
                        
  </div>
  