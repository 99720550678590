import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';

registerLocaleData(localeEn);

@Component({
  selector: 'app-euro-fuel-type',
  templateUrl: './euro-fuel-type.component.html',
  styleUrls: ['./euro-fuel-type.component.scss']
})
export class EuroFuelTypeComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  myChart;
  id;
  newQuery;
  neweuro4Count = 0;
  newNoneEuro4Count = 0;
  total;
  @Input() set upToEuro4Count(upToEuro4Count: object) {
    this.newNoneEuro4Count = Number(upToEuro4Count);
    this.ngOnInit();
  }

  @Input() set euro4Plus(euro4Plus: object) {
    this.neweuro4Count = Number(euro4Plus);
    this.ngOnInit();
  }

  @Input() set query(query: object) {
    this.query = query;
  }
  ngOnInit() {
        this.euro4Count = 0;
        this.euro4 = [];
        this.noneEuro4 = [];
        this.total = this.neweuro4Count + this.newNoneEuro4Count;
        this.euro4Count = (this.neweuro4Count/this.total)*100;
        this.noneEuro4Count = (this.newNoneEuro4Count/this.total)*100;
        this.euro4.push( this.euro4Count );
        this.noneEuro4.push( this.noneEuro4Count );
        if( this.noneEuro4.length > 0 ){
          this.loading = false;
        }
        this.data = {
          labels: [''],
          datasets: [
            {
              label: 'Euro 4-',
              data: this.noneEuro4,
              backgroundColor: ['#b9c9d6'],
              borderWidth: 1
            },
            {
          label: 'Euro 4+',
          data: this.euro4,
          backgroundColor: ['#51789a'],
          borderWidth: 1
          }
        ]
        }
        if( this.myChart ){
          this.myChart.destroy();
        }
        this.canvas = document.getElementById("fuelTypeChart");
        this.ctx = this.canvas.getContext('2d');
         this.myChart = new Chart(this.ctx, {
          type: 'bar',
          data: this.data,
          options: {
            indexAxis: 'y',
            elements: {
              bar: {
                borderWidth: 3,
              }
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                ticks:{
                  precision: 0,
                }
              },
              y: {
                display:false,
                ticks: {
                  display: false
              },
                stacked: true,
              }
            },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function(context) {
                      var data = context.dataset.data[0];
                      var label = context.dataset.label || '';

                      if (label) {
                          label += ': ';
                      }
                      if (context.parsed.y !== null) {
                          label += data.toFixed(2);
                      }
                      return label+"%";
                  },
                }
              },
              legend: {
                position: 'top',
                align: "end",
                labels: {
                  usePointStyle: true,
                  color: 'white'
                },
              },
              title: {
                display: false,
                position: 'top',
                text: 'Petrol'
              }
            }
          },
        });
        this.ctx.pointStyle= "circle";
      // });
      if( this.myChart )
        this.myChart.update();
      
  }
  
  loading = true;
}
