import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';
  
@Component({
  selector: 'app-vehicles-percentage',
  templateUrl: './vehicles-percentage.component.html',
  styleUrls: ['./vehicles-percentage.component.scss']
})
export class VehiclesPercentageComponent implements OnInit {
    constructor(private cubejs:CubejsClient){}
    dataSource = [];
    euro4Count = 0;
    euro4 = [];
    noneEuro4Count = 0;
    noneEuro4 = [];
    canvas: any;
    ctx: any;
    data;
    labels = [];
    percentage;
    value;
    newValue = [];
    newDataSource = [];
    newLSource = [];
    totalCount= 0;
    newQuery;
    @Input() set dataValue(dataValue: object) {
      this.value = dataValue;
    }
    @Input() set query(query: object) {
      this.newQuery = query;
      this.ngOnInit()
    }
    ngOnInit() {
      this.cubejs.load(this.newQuery).subscribe(
        resultSet => {
          this.dataSource = [];
          this.newDataSource = [];
          this.newValue = [];
          this.newLSource = [];
          this.euro4Count = 0;
          this.noneEuro4 = [];
          this.percentage = 0;
          this.labels = [];
          this.noneEuro4Count = 0;
          this.totalCount = 0;
          this.noneEuro4Count = 0;
          this.dataSource = resultSet.tablePivot();
          // this.dataSource.sort(function(a, b) {
          //     var textA = a['History.make'].toUpperCase();
          //     var textB = b['History.make'].toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // });
          if( this.dataSource.length > 0 ){
            for(var m in this.dataSource)
            {
              this.newDataSource.push(this.dataSource[m]['History.make']);
              if( this.dataSource[m]['History.make'] !== "" )
                this.totalCount = this.totalCount + this.dataSource[m]['History.count'];
            }
            this.labels.push( "Make                   " + "Count                 " + "% of Total" );
            this.noneEuro4.push( "" );
            for(var i in this.value)
            {
              if( this.newDataSource.includes(this.value[i]['History.make']) ){
                for(var index in this.dataSource)
                {
                    if( !this.newLSource.includes( this.dataSource[index]['History.make'] ) ){
                      if(this.dataSource[index]['History.make'] !== ""){
                        this.noneEuro4Count = this.dataSource[index]['History.count'];
                        this.noneEuro4.push( this.noneEuro4Count );
                        this.percentage = this.dataSource[index]['History.count']/this.totalCount*100;
                        this.percentage = this.percentage.toFixed(2);
                        if( this.percentage >= 10 ){
                          var str1 = [];
                          var str2 = "";
                          var str3 = "";
                          var re = /[^0-9](?=[0-9])/g;
                          var str = this.dataSource[index]['History.make'].trim() + this.dataSource[index]['History.count'] + " " + this.percentage + "%";
                          str = str.replace(re, '$&                   ');
                          if( str.indexOf('.') !== -1 ){
                            str1 = str.split(/[,.]/);
                            str2 = str1[1];
                            str3 = str1[0] + "." + str2.trim();
                          }else{
                            this.percentage = this.percentage.toFixed(2);
                            var str = this.dataSource[index]['History.make'].trim() + this.dataSource[index]['History.count'] + this.percentage + "%";
                            str = str.replace(re, '$&');
                            str3 = str;
                          }
                          this.labels.push( str3 );
                        }else{
                          var str1 = [];
                          var str2 = "";
                          var str3 = "";
                          var re = /[^0-9](?=[0-9])/g;
                          var str = this.dataSource[index]['History.make'].trim() + this.dataSource[index]['History.count'] + " " + this.percentage + "%";
                          str = str.replace(re, '$&                    ');
                          if( str.indexOf('.') !== -1 ){
                            str1 = str.split(/[,.]/);
                            str2 = str1[1];
                            str3 = str1[0] + "." + str2.trim();
                          }else{
                            var str = this.dataSource[index]['History.make'].trim() + this.dataSource[index]['History.count']+ "   " + this.percentage + "%";
                            str = str.replace(re, '$&                      ');
                            str3 = str;
                          }
                          this.labels.push( str3 );
                        }
                      }
                        this.newLSource.push( this.dataSource[index]['History.make'] )
                        // break;
                    }
                    // console.log( this.noneEuro4Count.toFixed(2) );
                    // this.totalCount =  Number(this.noneEuro4Count.toFixed(2));
                }
              }
            }
            // for(var index in this.dataSource)
            //     { 
            //       if(this.dataSource[index]['History.make'] == ""){
            //         this.dataSource[index]['History.make'] = "Unknown/Others"
            //       }
            //     }
                
            this.euro4.push( this.euro4Count );
            this.data = {
            labels: this.labels,
            datasets: [{
              axis: 'y',
              label: 'Manufacture',
              data: this.noneEuro4,
              fill: false,
              fontColor: '#fff',
              backgroundColor: [
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
              ],
              borderColor: [
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
                '#b9c9d6',
              ],
              borderWidth: 1
            }]
          };
          if( this.myChart ){
            this.myChart.destroy();
          }
          this.canvas = document.getElementById('vehicles-perentage');
            this.ctx = this.canvas.getContext('2d');
            this.myChart = new Chart(this.ctx, {
              type: 'bar',
              data: this.data,
              options: {
                indexAxis: 'y',
                elements: {
                  bar: {
                    borderWidth: 3,
                  }
                },
                responsive: true,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: true,
                    ticks: {
                      color: 'white',
                      padding: 10
                      // callback: function(value, index, values) {
                      //     console.log(value);
                      //     console.log(index);
                      //     console.log(value);
                      //     return '$' + value;
                      // }

                    }
                    
                  },
                },
                plugins: {
                  tooltip:{
                    enabled: false
                  },
                  legend: {
                    display:false,
                    position: 'right',
                    labels: {
                      usePointStyle: true,
                    },
                  },
                  title: {
                    display: false,
                    text: '% of Total'
                  }
                }
              },
            });
            }
          });
          if( this.myChart ){
            this.myChart.update();
          }
        
    }
    changeItemColor = (item) => {
      item.scaleLabel.fontColor = "#FFF";
      item.ticks.fontColor = "#FFF";
      item.ticks.minor.fontColor = "#FFF";
      item.ticks.major.fontColor = "#FFF";
  };
    myChart;
  }
  