<!--<div class="table-warp" style="background-color: #242533;padding: 0px 10px 0 10px;position: fixed;top: 0;width: 100%;z-index: 1;">
  <div class="row narrow-row">
    <div class="col-12">
      <div class="insights-cards-wrapper">
        <div class="row narrow-row">
          <div class="col-2">
            <a [routerLink]="[redirectLinkActivity, href]">
              <div class="insights-card"> <i class="fas fa-exchange"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkAlerts, href]">
              <div class="insights-card active"> <i class="fas fa-bell"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkVehicles, href]">
              <div class="insights-card"> <i class="fas fa-car"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkDevices, href]">
              <div class="insights-card"> <i class="fas fa-laptop"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkPeople, href]">
              <div class="insights-card"> <i class="fas fa-user"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[acessBespoke, href]">
              <div class="insights-card"> <i class="fas fa-star"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-bell"></i>Alerts Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
    </select>
  </div>
</div>

<div class="table-warp">
  <div class="row narrow-row">
    <div class="col-6">
      <div class="kpi-wrap">
            <app-call-alerts-cards
                        [query]="alertsCards" 
                        [queryVehicles]="queryVehicles"
                        [queryDevice]="queryDevice"
                        [queryPeople]="queryPeople"
          ></app-call-alerts-cards>
      </div>
      <app-call-alerts-identifier-type [query]="query2"></app-call-alerts-identifier-type>
    </div>
    <div class="col-6">
      <app-call-alerts-priority [query]="query"></app-call-alerts-priority>
      <app-call-alerts-identifier [query]="query1"></app-call-alerts-identifier>
      <app-call-alerts-actionreason [query]="query3"></app-call-alerts-actionreason>
      <app-call-alerts-resolvedbyuseremail [query]="query4"></app-call-alerts-resolvedbyuseremail>
      <app-call-alerts-source [query]="query5"></app-call-alerts-source>
      <app-call-alerts-resolvedreason [query]="query6"></app-call-alerts-resolvedreason>
      <app-call-alerts-type [query]="query0"></app-call-alerts-type>
    </div>
  </div>
</div>
