<!-- <div>
    <div style="display: block;height: 400px;">
      <canvas baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType">
      </canvas>
    </div>
  </div> -->
  <div class="example-loading-shade fuel-loader"
  *ngIf="loading">
  <mat-spinner></mat-spinner>
  </div>
  <canvas id="fuelTypeChart" width="1320" height="500"></canvas>

