import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { exit } from "process";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  
  //  public apiUrl = "http://network.advancedstudio.me/getDevicesAPI";
  public apiUrl = "https://network.advancedstudio.co.uk/getDevicesAPI";
  // public apiUrl1 = "http://network.advancedstudio.me/getDevicesBySourceAPI";
  public apiUrl1 = "https://network.advancedstudio.co.uk/getDevicesBySourceAPI";
  // public apiUrl2 = "http://network.advancedstudio.me/getDevicesMake";
  public apiUrl2 = "https://network.advancedstudio.co.uk/getDevicesMake";
  public deviceAPIData = [];
  public deviceAPIData1 = [];
  public deviceAPIData2 = [];
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient, private httpClient: HttpClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //     // some code..
    //   }else{
    //     if ( window.location == window.parent.location ) {
    //       window.location.href = "/#/revoke"
    //     }
    //   }
  }

  setData(response){
    this.deviceAPIData = response.data;
  }

  setData1(response1){
    this.deviceAPIData1 = Object.values(response1.data);
  }

  setData2(response2){
    this.deviceAPIData2 = Object.values(response2.data);
  }

  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public limit = 10;
  public limit1 = 10;
  public limit2 = 10;
  public limit3 = 10;
  public limit4 = 10;
  public limit5 = 10;
  public limit6 = 10;
  public page = 0;
  public page1 = 0;
  public queryVehicelsFuelType = null;
  public queryVehicelsFuelTypePetrol = null;
  public queryVehicelsFuelTypeDiesel = null;
  public queryVehicelsFuelTypeHybrid = null;
  public queryVehicelsFuelTypeElectric = null;
  public queryVehicelsFuelTypeOther = null;
  public queryVehicelsFuelTypeTaxStatus = null;
  public queryVehicelsFuelTypeTaxed = null;
  public queryVehicelsFuelTypeUnTaxed = null;
  public queryVehicelsFuelTypeSorn = null;
  public queryVehicelsFuelTypeunknown = null;
  public queryVehicelsFuelTypeMot = null;
  public queryVehicelsFuelTypeValid = null;
  public queryVehicelsFuelTypeNotValid = null;
  public queryVehicelsFuelTypeMotUnknown = null;
  public queryVehicelsManufacturer = null;
  public queryVehicelsEuroStatus = null;
  public queryVehicelsType = null;
  public queryVehicelsTrafficFlow = null;
  public queryDevicesOnlineOffline = null;
  public queryMake = null;
  public querySites = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This Year', name: 'This Year' },
  ];
  public selected: string = "Today";
  selectOption(id: string) {
    this.selected = id;
  this._queryDevicesOnlineOffline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "dimensions": [
      "History.metadatacondition"
    ],
    "order": {
      "History.count": "desc"
    },
    "filters": [
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member":"History.metadatacondition",
        "operator": "contains",
        "values": [
          "online",
          "offline"
        ]
      }
    ]
  });
  this._queryEvents = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "equals",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      }
    ]
  });
  this._queryMake = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "dimensions": [
      "History.make"
    ],
    "order": {
      "History.count": "desc"
    },
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ]
  });
  this._queryTopDevices = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.metadata",
      "History.metadatacondition",
      "History.identifier"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadatacondition",
        "operator": "contains",
        "values": [
          "online",
          "offline"
        ]
      }
    ]
  });
    this.ngOnInit();
  }
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }else{
    this._queryDevicesOnlineOffline = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "dimensions": [
        "History.metadatacondition"
      ],
      "order": {
        "History.count": "desc"
      },
      "filters": [
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member":"History.metadatacondition",
          "operator": "contains",
          "values": [
            "online",
            "offline"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this._queryEvents = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });

    this._queryMake = new BehaviorSubject({
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "dimensions": [
        "History.make"
      ],
      "order": {
        "History.count": "desc"
      },
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ],
      "limit": 10,
      "measures": [
        "History.count"
      ]
    });

    this._queryTopDevices = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.metadata",
        "History.metadatacondition",
        "History.identifier"
      ],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.identifiertype",
          "operator": "contains",
          "values": [
            "device"
          ]
        },
        {
          "member": "History.metadatacondition",
          "operator": "contains",
          "values": [
            "online",
            "offline"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.ngOnInit();
    
  }
}
  public _queryDevicesOnlineOffline = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "dimensions": [
      "History.metadatacondition"
    ],
    "order": {
      "History.count": "desc"
    },
    "filters": [
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member":"History.metadatacondition",
        "operator": "contains",
        "values": [
          "online",
          "offline"
        ]
      }
    ]
  });

  public _queryTopDevices = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.metadata",
      "History.metadatacondition",
      "History.identifier"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      },
      {
        "member": "History.metadatacondition",
        "operator": "contains",
        "values": [
          "online",
          "offline"
        ]
      }
    ]
  });

  public _querySource = new BehaviorSubject({
    "measures": [
      "Sources.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Sources.createddate"
      }
    ],
    "filters": [
      {
        "member": "Sources.tenantkey",
        "operator": "equals",
        "values": [
          this.href
        ]
      },
      {
        "member": "Sources.identifiertypeid",
        "operator":
        "equals",
        "values":
        [
          "56"
        ]
      }
    ]
  });

  public _queryEvents = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "equals",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      }
    ]
  });

  public _queryMake = new BehaviorSubject({
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": this.selected
      }
    ],
    "dimensions": [
      "History.make"
    ],
    "order": {
      "History.count": "desc"
    },
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "History.identifiertype",
        "operator": "contains",
        "values": [
          "device"
        ]
      }
    ],
    "limit": 10,
    "measures": [
      "History.count"
    ]
  });
 
  public _querySites = new BehaviorSubject(
    {
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "Layers.createddate"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "dimensions": [
        "Layers.name"
      ]
    }
  );
  
  public dataSource = [];
  public newDataSource = [];
  public newDataSourceTax = [];
  name: string;
  public newCubeJs;
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public acessBespoke;
  public currentUrl;
  public bespokeLink;
  vehicelsTypeData: Array<{name: string}> = []; 
  public deviceOnlineOffline;
  public querySource;
  public sourceListing;
  public queryEvents;
  public eventsListing;
  public makeListing;
  public deviceTopOnlineOffline;
  public queryTopDevices;
  public topDevicesListing;
  public topDeviceListing;
  public topOfflineDeviceListing;
  public selectedDate = 'Today';
  ngOnInit(): void {
    const xhttp = new XMLHttpRequest();
    var that = this;
    xhttp.onload = function() {
      let response = JSON.parse( this.response);
        that.setData(response);
    }
    this.selectedDate = this.selected;
    xhttp.open("GET", this.apiUrl + "?Selected=" + this.selectedDate.replace(/\s/g, '') + "&org_id=" + this.href);
    xhttp.send();

    const xhttp1 = new XMLHttpRequest();
    var that1 = this;
    xhttp1.onload = function() {
      let response1 = JSON.parse( this.response);
        that1.setData1(response1);
    }
    xhttp1.open("GET", this.apiUrl1 + "?org_id=" + this.href);
    xhttp1.send();

    const xhttp2 = new XMLHttpRequest();
    var that2 = this;
    xhttp2.onload = function() {
      let response2 = JSON.parse( this.response);
        that2.setData2(response2);
    }
    xhttp2.open("GET", this.apiUrl2 + "?org_id=" + this.href);
    xhttp2.send();

    this.urlLink = this.router.url;
      
        this.redirectLinkActivity = "/activity";
        this.redirectLinkAlerts = "/alerts";
        this.redirectLinkVehicles = "/vehicles";
        this.redirectLinkDevices = "/devices";
        this.redirectLinkPeople = "/people";
        this.redirectLinkBespoke = "/bespoke";
        this.bespokeLink = "/bespoke";
        this.acessBespoke = "/bespoke-w";
        // this.currentUrl = "/insights-dashboard";
      
    this._queryDevicesOnlineOffline.subscribe(query => {
      this.queryDevicesOnlineOffline = query;
      this.cubejs.load(this.queryDevicesOnlineOffline).subscribe(
        resultSet => {
          this.deviceOnlineOffline = resultSet.tablePivot();
        },
        err => console.log("HTTP Error", err)
      );
    });

    this._queryEvents.subscribe(query => {
      this.queryEvents = query;
      this.cubejs.load(this.queryEvents).subscribe(
        resultSet => {
          this.eventsListing = resultSet.tablePivot();
        },
        err => console.log("HTTP Error", err)
      );
    });

    this._queryMake.subscribe(query => {
      this.queryMake = query;
      this.cubejs.load(this.queryMake).subscribe(
        resultSet => {
          this.makeListing = resultSet.tablePivot();
        },
        err => console.log("HTTP Error", err)
      );
    });

    this._queryTopDevices.subscribe(queryTopDevices => {
      this.queryTopDevices = queryTopDevices;
      this.cubejs.load(this.queryTopDevices).subscribe(
        resultSet => {
          this.topDevicesListing = resultSet.tablePivot();
          var topDevice = this.topDevicesListing;
          var data = [];
          var offlineData = [];
          var newData = [];
          var newCount = 0;
          var resultArray = [];
          var resultOffineArray = [];
          for( var i=0; i < topDevice.length; i++ ){
            var count = topDevice[i]['History.count'];
            var identifier = topDevice[i]['History.identifier'];
            var metadata = topDevice[i]['History.metadata'];
            var metadatacondition = topDevice[i]['History.metadatacondition'];
            metadata =  JSON.parse(metadata);
            metadata["identifier"] = identifier;
            metadata["count"] = count;
              if( data.indexOf(identifier) !== -1 ){
                // do nothing
              }else{
                if( metadata['name'] != "" )
                  resultArray.push({"newIdentifier":identifier, "name" : metadata['name'], 'metadatacondition': metadatacondition,"newCount": 0});
                else
                  resultArray.push({"newIdentifier":identifier, 'metadatacondition': metadatacondition,"newCount": 0});
                data.push(identifier);
              }

              // if( data.indexOf(identifier) !== -1 ){
              //   loop1:
                  // for( var m = 0; m < data.length; m++ ){
                  //   var dataIdentifier = data[m];
                  //   loop2:
                      for( var l = 0; l < resultArray.length; l++ ){
                        var dataResult = resultArray[l];
                        if( dataResult['newIdentifier'] == metadata["identifier"] ){
                          dataResult['newCount'] = count + dataResult['newCount'];
                          // break loop2;
                        }
                    // }
                  // }
              }
            newData.push(metadata);
          }
          this.topDeviceListing = resultArray;
          this.topOfflineDeviceListing = resultOffineArray;
        },
        err => console.log("HTTP Error", err)
      );
    });

    this._querySource.subscribe(query => {
      this.querySource = query;
      this.newCubeJs = new CubejsClient(
        {
          "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
          "options": {
              "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
          }
      });
      this.newCubeJs.load(this.querySource).subscribe(
        resultSet => {
          this.sourceListing = resultSet.tablePivot();
        },
        err => console.log("HTTP Error", err)
      );
    });

    if( this.siteSelected == "All" ){
      this._querySites.subscribe(querySite => {
        this.newCubeJs = new CubejsClient(
          {
            "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
            "options": {
                "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
            }
        });
        this.querySites = querySite;
        this.newCubeJs.load(this.querySites).subscribe(
          resultSet => {
            this.dataSource = resultSet.rawData();
            this.dataSource.sort(this.dynamicSort("Layers.name"));
          },
          err => console.log("HTTP Error", err)
        );
      });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

