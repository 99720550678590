import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';


@Component({
  selector: 'app-euro-fuel-euro6-count',
  templateUrl: './euro-fuel-euro6-count.component.html',
  styleUrls: ['./euro-fuel-euro6-count.component.scss']
})
export class EuroFuelEuro6CountComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  labels = [];
  percentage;
  value;
  newValue = [];
  newDataSource = [];
  newLSource = [];
  volumnCountTotal;
  @Input() set volumnCount(volumnCount: object) {
    this.volumnCountTotal = volumnCount;
  }
  @Input() set dataValue(dataValue: object) {
    this.value = dataValue;
  }
  @Input() set query(query: object) {
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.newDataSource = [];
        this.newValue = [];
        this.newLSource = [];
        this.euro4Count = 0;
        this.noneEuro4 = [];
        this.percentage = 0;
        this.labels = [];
        this.loading = true;
        this.dataSource = resultSet.tablePivot();
        this.interval = setTimeout(() => { 
          this.buildChart(); 
        }, 1000);
      });

  }
  ngOnInit() {
    
  }
  interval;
  buildChart(){
    
    this.dataSource.sort(function(a, b) {
      var textA = a['History.vehiclefueltype'].toUpperCase();
      var textB = b['History.vehiclefueltype'].toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    if( this.dataSource.length > 0 ){
      for(var index in this.dataSource)
      {
        this.newDataSource.push(this.dataSource[index]['History.vehiclefueltype']);
      }
      for(var index in this.dataSource)
        {
          loop:
          for(var i in this.value)
          {
            if( this.value[i]['History.vehiclefueltype'] == this.dataSource[index]['History.vehiclefueltype'] ){
              this.noneEuro4Count = 0;
              if( !this.newLSource.includes( this.value[i]['History.vehiclefueltype'] ) ){
                if(this.dataSource[index]['History.vehiclefueltype'] == ""){
                }else{
                  this.count = this.dataSource[index]['History.count'];
                  this.percentage = (this.count/this.volumnCountTotal)*100;
                  this.percentage = Number(this.percentage.toFixed(2));
                  this.labels.push( {"History.vehiclefueltype": this.dataSource[index]['History.vehiclefueltype'], "History.percent": this.percentage, "History.count": this.count });
                }
              }
                  this.newLSource.push( this.dataSource[index]['History.vehiclefueltype'] );
              break loop;
            }else{
              if( !this.newLSource.includes(this.value[i]['History.vehiclefueltype']) ){
                this.count = 0;
                this.percentage = 0;
                this.labels.push( {"History.vehiclefueltype": this.value[i]['History.vehiclefueltype'], "History.percent": this.percentage, "History.count": this.count });
                this.newLSource.push( this.value[i]['History.vehiclefueltype'] );
              }
            }      
          }
      }
    }else if( this.dataSource.length == 0){
      for(var lm = 0; lm < this.value.length; lm++)
        {
          this.count = 0;
            this.percentage = 0;
            this.labels.push( {"History.vehiclefueltype": this.value[lm]['History.vehiclefueltype'], "History.percent": this.percentage, "History.count": this.count });
        }
    }
    this.loading = false;
  }
  myChart;
  count = 0;
  loading = true;
}
