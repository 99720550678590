<!--<div class="table-warp" style="background-color: #242533;padding: 0px 10px 0 10px;position: fixed;top: 0;width: 100%;z-index: 1;">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="insights-cards-wrapper">
          <div class="row narrow-row">
            <div class="col-2">
              <a [routerLink]="[redirectLinkActivity, href]">
                <div class="insights-card"> <i class="fas fa-exchange"></i>
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkAlerts, href]">
                <div class="insights-card"> <i class="fas fa-bell"></i>
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkVehicles, href]">
                <div class="insights-card"> <i class="fas fa-car"></i>
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkDevices, href]">
                <div class="insights-card"> <i class="fas fa-laptop"></i>
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkPeople, href]">
                <div class="insights-card"> <i class="fas fa-user"></i>
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[acessBespoke, href]">
                <div class="insights-card active"> <i class="fas fa-star"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
    <div class="table-warp" *ngIf="denied" style="position: absolute;left: 30%;color: #FFF;padding: 0px 0 10px 0;margin: 0 10px;margin-top: 50px;padding: 80px;">
        <div class="row narrow-row">
            <div class="col-12">To enable bespoke reporting email: bespoke@intelico.com</div>
        </div>
    </div>
  <div class="table-warp" *ngIf="access" style="padding: 0px 0 10px 0;margin: 0 10px;margin-top: 65px;">
    <div class="row narrow-row">
      <div class="col-12">
        <div *ngIf="emissions">
          <div class="col-6" style="float: left;">
              <a [routerLink]="[fuelType, href]">
              <div class="insights-card" style="height: 150px;"> <i class="fas fa-cars"></i>
                  <h5>Vehicle Emissions</h5>
              </div>
              </a>
          </div>
        </div>
        <div *ngIf="report">
          <div class="col-6" style="float: left;">
            <a [routerLink]="[export_csv, href]">
            <div class="insights-card" style="height: 150px;"> <i class="fas fa-list"></i>
                <h5>Export</h5>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  