import { Component, Input, Output } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-vehicles-type',
  templateUrl: './vehicles-type.component.html',
  styleUrls: ['./vehicles-type.component.scss']
})
export class VehiclesTypeComponent{
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
            if( ndata['History.vehicletypeapproval'] == null ){
              this.dataSource.splice(i,1)
            }
            if( ndata['History.vehicletypeapproval'] == "" ){
              this.dataSource.splice(i,1)
            }

          // if( ndata['History.vehicletypeapproval'] != "" || ndata['History.vehicletypeapproval'] != null){
            if( ndata['History.vehicletypeapproval'] == "M1" ){
              ndata['History.vehicletypeapproval'] = ndata['History.vehicletypeapproval'] + " Passenger Cars";
            }
            if( ndata['History.vehicletypeapproval'] == "M2" || ndata['History.vehicletypeapproval'] == "M3" ){
              ndata['History.vehicletypeapproval'] = ndata['History.vehicletypeapproval'] + " Buses and Coaches";
            }
            if( ndata['History.vehicletypeapproval'] == "N1" ){
              ndata['History.vehicletypeapproval'] = ndata['History.vehicletypeapproval'] + " Light Goods Vehicles";
            }
            if( ndata['History.vehicletypeapproval'] == "N2" || ndata['History.vehicletypeapproval'] == "N3"  ){
              ndata['History.vehicletypeapproval'] =  ndata['History.vehicletypeapproval'] + " Heavy Goods Vehicles";
            }
            if( ndata['History.vehicletypeapproval'] == "L1" || ndata['History.vehicletypeapproval'] == "L2" || ndata['History.vehicletypeapproval'] == "L3"  ){
              ndata['History.vehicletypeapproval'] =  ndata['History.vehicletypeapproval'] + " Motorbikes";
            }
          }
      //   this.dataSource.forEach((element,index)=>{
      //     if( element['History.vehicletypeapproval']=="Other/Unknown" ){
      //       this.count = element['History.count'] + this.count;
      //       element['History.count'] = this.count;
      //     }
      //     if( element['History.vehicletypeapproval']=="Other/Unknown1" ){
      //       this.count = element['History.count'] + this.count;
      //       this.dataSource.splice(index,1)
      //     }
      //  });
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  count = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  nDataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
