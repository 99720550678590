import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-vehicles-count',
  templateUrl: './vehicles-count.component.html',
  styleUrls: ['./vehicles-count.component.scss']
})
export class VehiclesCountComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  labels = [];
  percentage;
  value;
  newValue = [];
  newDataSource = [];
  newLSource = [];
  newQuery;
  @Input() set dataValue(dataValue: object) {
    this.value = dataValue;
  }
  @Input() set query(query: object) {
    this.newQuery = query;
    this.ngOnInit()
  }
  ngOnInit() {
    this.cubejs.load(this.newQuery).subscribe(
      resultSet => {
        this.dataSource = [];
        this.newDataSource = [];
        this.newValue = [];
        this.newLSource = [];
        this.euro4Count = 0;
        this.noneEuro4 = [];
        this.percentage = 0;
        this.labels = [];
        this.loading = true;
        this.dataSource = resultSet.tablePivot();
        // this.dataSource.sort(function(a, b) {
        //     var textA = a['History.make'].toUpperCase();
        //     var textB = b['History.make'].toUpperCase();
        //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        // });
        for(var index in this.value)
        { 
          this.newValue.push(this.value[index]['History.make']);
        }
        for(var index in this.dataSource)
        {
          this.newDataSource.push(this.dataSource[index]['History.make']);
        }
        for(var i in this.newValue)
        { 
          if( this.newDataSource.includes(this.newValue[i]) ){
            for(var index in this.dataSource)
            { 
              this.noneEuro4Count = 0;
                if( !this.newLSource.includes( this.dataSource[index]['History.make'] ) ){
                  if(this.dataSource[index]['History.make'] == ""){
                  }else{
                    this.noneEuro4Count = this.dataSource[index]['History.count'];
                    this.noneEuro4.push( this.noneEuro4Count );
                    this.percentage = this.dataSource[index]['History.count'];
                    this.labels.push( this.percentage );
                  }
                    this.newLSource.push( this.dataSource[index]['History.make'] )
                    // break;
                }
                
            }
          }
        }
        this.euro4.push( this.euro4Count );
        if( this.noneEuro4.length > 0 ){
          this.loading = false;
        
        this.data = {
        labels: this.labels,
        datasets: [{
          axis: 'y',
          label: 'Manufacture',
          data: this.noneEuro4,
          fill: false,
          backgroundColor: [
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
          ],
          borderColor: [
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
            '#51789a',
          ],
          borderWidth: 1
        }]
      };
      if( this.myChart ){
        this.myChart.destroy();
      }
      this.canvas = document.getElementById('vehicles-count');
        this.ctx = this.canvas.getContext('2d');
        this.myChart = new Chart(this.ctx, {
          type: 'bar',
          data: this.data,
          options: {
            indexAxis: 'y',
            elements: {
              bar: {
                borderWidth: 3,
              }
            },
            responsive: true,
            scales: {
              x: {
                display: false,
              },
              y: {
                display: true,
                ticks: {
                  color: 'white'
                }
              },
            },
            plugins: {
              tooltip: {
                enabled: false
              },
              legend: {
                display:false,
                position: 'right',
                labels: {
                  usePointStyle: true,
                },
              },
              title: {
                display: false,
                text: '% of Total'
              }
            }
          },
        });
      }
      });
      if( this.myChart ){
        this.myChart.update();
      }
  }
  myChart;
  loading = true;
}
