<div class="table-warp mobile-nav mobile-view-table" style="background-color: #051f2c;padding: 0px 10px 0 10px;">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="insights-cards-wrapper">
          <div class="row narrow-row">
            <div class="col-2">
              <a [routerLink]="[redirectLinkActivity, href]">
                <div class="insights-card"> <i class="fas fa-exchange"></i>
                  <!-- <h5>Activity</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkAlerts, href]">
                <div class="insights-card"> <i class="fas fa-bell"></i>
                  <!-- <h5>Alerts</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkVehicles, href]">
                <div class="insights-card"> <i class="fas fa-car"></i>
                  <!-- <h5>Vehicles</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkDevices, href]">
                <div class="insights-card"> <i class="fas fa-laptop"></i>
                  <!-- <h5>Devices</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[redirectLinkPeople, href]">
                <div class="insights-card"> <i class="fas fa-user"></i>
                  <!-- <h5>People</h5> -->
                </div>
              </a>
            </div>
            <div class="col-2">
              <a [routerLink]="[acessBespoke, href]">
                <div class="insights-card active"> <i class="fas fa-star"></i>
                  <!-- <h5>People</h5> -->
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="table-warp" *ngIf="denied" style="color: #FFF;padding: 0px 0 10px 0;margin: 0 10px;margin-top: 65px;padding: 80px;">
        <div class="row narrow-row">
            <div class="col-12">To enable bespoke reporting email: bespoke@intelico.com</div>
        </div>
    </div>
  <div class="table-warp" *ngIf="access" style="padding: 0px 0 10px 0;margin: 0 10px;margin-top: 65px;">
    <div class="row narrow-row">
      <div class="col-12">
        <div class="col-6">
            <a [routerLink]="[fuelType, href]">
            <div class="insights-card" style="height: 150px;"> <i class="fas fa-cars"></i>
                <h5>Vehicle Emissions</h5>
            </div>
            </a>
        </div>
      </div>
    </div>
  </div>
