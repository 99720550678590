import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { $ } from "protractor";
import { HttpClient } from '@angular/common/http';
import { callback } from "chart.js/helpers";

@Component({
  selector: 'app-counting',
  templateUrl: './counting.component.html',
  styleUrls: ['./counting.component.scss']
})
export class CountingComponent implements OnInit {

  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public apiUrl = "https://api.advancedstudio.co.uk/features/getAllOrganizations?ID=" + this.href;
  public accessOrg = 0;
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient, private httpClient: HttpClient) {
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
  
    const xhttp = new XMLHttpRequest();
    var that = this;
    xhttp.onload = function() {
      if(this.status != 200){
        window.location.href = "/#/revoke";
      }
      let response = JSON.parse( this.response);
      if(response.success == 0){
        window.location.href = "/#/revoke";
      }else{
        that.repeat();
      }
    }
    xhttp.open("GET", this.apiUrl);
    xhttp.send();

    xhttp.onerror = function(){
      window.location.href = "/#/revoke";
    }
        
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
      }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }
    
  }
  
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This Year', name: 'This Year' },
  ];
  
  public _querySites = new BehaviorSubject(
    {
      "dimensions": [
        "Counters.counterName",
        "Counters.counterCapacity",
        "Counters.counterOccupied"
      ],
      "order": {
        "Counters.count": "desc"
      },
      "filters": [
        {
          "member": "Counters.counterCategory",
          "operator": "equals",
          "values": [
            "3"
          ]
        }
      ]
    }
  );

  public _queryLevel = new BehaviorSubject(
    {
      "dimensions": [
        "Counters.counterName",
        "Counters.counterCapacity",
        "Counters.counterOccupied"
      ],
      "order": {
        "Counters.count": "desc"
      },
      "filters": [
        {
          "member": "Counters.counterCategory",
          "operator": "equals",
          "values": [
            "2"
          ]
        }
      ]
    }
  );

  public _queryZone = new BehaviorSubject(
    {
      "dimensions": [
        "Counters.counterName",
        "Counters.counterCapacity",
        "Counters.counterOccupied"
      ],
      "order": {
        "Counters.count": "desc"
      },
      "filters": [
        {
          "member": "Counters.counterCategory",
          "operator": "equals",
          "values": [
            "1"
          ]
        }
      ]
    }
  );
  
  public dataSource = [];
  public newDataSource = [];
  public newDataSourceTax = [];
  name: string;
  public newCubeJs;
  public urlLink;
  public redirectLinkActivity;
  public redirectLinkAlerts;
  public redirectLinkVehicles;
  public redirectLinkDevices;
  public redirectLinkPeople;
  public redirectLinkBespoke;
  public redirectLinkPartners;
  public acessBespoke;
  public currentUrl;
  public bespokeLink;
  public loading = false;
  
  public querySites;
  public queryLevel;
  public queryZone;

  public counterName;
  public counterCapacity;
  public counterOccupied;
  public total;
  public free;

  public counterLevelName;
  public counterLevelCapacity;
  public counterLevelOccupied;
  public Leveltotal;
  public Levelfree;
  public dataLevelSource =[];

  public counterZoneName;
  public counterZoneCapacity;
  public counterZoneOccupied;
  public Zonetotal;
  public Zonefree;
  public dataZoneSource =[];
  public newdataZoneSource = [];
  vehicelsTypeData: Array<{name: string}> = []; 
  ngOnInit(): void {
    this.urlLink = this.router.url;
        this.redirectLinkActivity = "/activity";
        this.redirectLinkAlerts = "/alerts";
        this.redirectLinkVehicles = "/vehicles";
        this.redirectLinkDevices = "/devices";
        this.redirectLinkPeople = "/people";
        this.redirectLinkBespoke = "/bespoke";
        this.bespokeLink = "/bespoke";
        this.acessBespoke = "/bespoke-w";
        // this.currentUrl = "/insights-dashboard";

          this._querySites.subscribe(querySite => {
            this.newCubeJs = new CubejsClient(
              {
                "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTcxOTAwMjl9.4-RLXL5EDH67DzB8I9RjdWJAA7BEWcsxWh6eMooyvJM',
                "options": {
                    "apiUrl": 'https://official-cambria.aws-eu-west-1.cubecloudapp.dev/cubejs-api/v1'
                }
            });
            this.querySites = querySite;
            this.newCubeJs.load(this.querySites).subscribe(
              resultSet => {
                this.counterCapacity = 0;
                this.counterOccupied = 0;
                this.total = 0;
                this.free = 0;
                this.dataSource = resultSet.rawData();
                this.counterName = this.dataSource[0]['Counters.counterName'];
                this.counterCapacity = this.dataSource[0]['Counters.counterCapacity'];
                this.counterOccupied = this.dataSource[0]['Counters.counterOccupied'];
                this.total = this.counterCapacity;
                this.free = this.total - this.counterOccupied;
                // this.dataSource.sort(this.dynamicSort("Layers.name"));
              },
              err => console.log("HTTP Error", err)
            );
          });


          this._queryLevel.subscribe(queryLevel => {
              this.newCubeJs = new CubejsClient(
                {
                  "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTcxOTAwMjl9.4-RLXL5EDH67DzB8I9RjdWJAA7BEWcsxWh6eMooyvJM',
                  "options": {
                      "apiUrl": 'https://official-cambria.aws-eu-west-1.cubecloudapp.dev/cubejs-api/v1'
                  }
              });
            this.queryLevel = queryLevel;
            this.newCubeJs.load(this.queryLevel).subscribe(
              resultSet => {
                this.counterLevelCapacity = 0;
                this.counterLevelOccupied = 0;
                this.Leveltotal = 0;
                this.Levelfree = 0;
                this.dataLevelSource = resultSet.rawData();
                this.counterLevelName = this.dataLevelSource[0]['Counters.counterName'];
                this.counterLevelCapacity = this.dataLevelSource[0]['Counters.counterCapacity'];
                this.counterLevelOccupied = this.dataLevelSource[0]['Counters.counterOccupied'];
                this.Leveltotal = this.counterLevelCapacity;
                this.Levelfree = this.Leveltotal - this.counterLevelOccupied;
                // this.dataSource.sort(this.dynamicSort("Layers.name"));
              },
              err => console.log("HTTP Error", err)
            );
          });

          this._queryZone.subscribe(queryZone => {
            this.newCubeJs = new CubejsClient(
              {
                "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTcxOTAwMjl9.4-RLXL5EDH67DzB8I9RjdWJAA7BEWcsxWh6eMooyvJM',
                "options": {
                    "apiUrl": 'https://official-cambria.aws-eu-west-1.cubecloudapp.dev/cubejs-api/v1'
                }
            });
          this.queryZone = queryZone;
          this.newCubeJs.load(this.queryZone).subscribe(
            resultSet => {
              this.counterZoneCapacity = 0;
              this.counterZoneOccupied = 0;
              this.Zonetotal = 0;
              this.Zonefree = 0;
              this.newdataZoneSource = [];
              this.dataZoneSource = resultSet.rawData();
              for( var i=0; i<this.dataZoneSource.length;i++ ){
                this.counterZoneName = this.dataZoneSource[i]['Counters.counterName'];
                this.counterZoneCapacity = this.dataZoneSource[i]['Counters.counterCapacity'];
                this.counterZoneOccupied = this.dataZoneSource[i]['Counters.counterOccupied'];
                this.Zonetotal = this.counterZoneCapacity;
                this.Zonefree = this.Zonetotal - this.counterZoneOccupied;
                this.newdataZoneSource.push( { "counterName" : this.counterZoneName, "counterZoneOccupied" : this.counterZoneOccupied, "Zonetotal": this.Zonetotal, "Zonefree": this.Zonefree } )
              }
              // this.dataSource.sort(this.dynamicSort("Layers.name"));
            },
            err => console.log("HTTP Error", err)
          );
        });
  }

  repeat(){
    setInterval(() => {
      this.ngOnInit();
    }, 30000);
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}

}
