import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";
import { stringify } from "@angular/compiler/src/util";

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss'],
  styles: ['html { background-color: #FFF !important; }']
})
export class AlertCardComponent implements OnInit {
  cubeQuery = new BehaviorSubject(null);
  chartType = new BehaviorSubject(null);
  pivotConfig = new BehaviorSubject(null);
  constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
    document.body.style.backgroundColor = "#051F2C";
    this.title.setTitle('Angular Dashboard with Material');
    this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
    this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
      }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }
  }
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public limit = 10;
  public limit1 = 10;
  public limit2 = 10;
  public limit3 = 10;
  public limit4 = 10;
  public limit5 = 10;
  public limit6 = 10;
  public page = 0;
  public page1 = 0;
  public page2 = 0;
  public page3 = 0;
  public page4 = 0;
  public page5 = 0;
  public page6 = 0;
  public query = null;
  public queryPie = null;
  public query0 = null;
  public query1 = null;
  public query2 = null;
  public query3 = null;
  public query4 = null;
  public query5 = null;
  public query6 = null;
  public query7 = null;
  public alertsCards = null;
  query7_0Data = null;
  public sorting = ['History.createdat', 'desc'];
  public startDate = "01/1/2019";
  public finishDate = "01/1/2022";
  cols : number;
  chartCols: number;
  gridByBreakpoint = {
    xl: 4,
    lg: 4,
    md: 2,
    sm: 2,
    xs: 1
  }
  chartGridByBreakpoint = {
    xl: 5,
    lg: 5,
    md: 3,
    sm: 3,
    xs: 3
  }
  dates: any[] = [
    { id: 'Today', name: 'Today' },
    { id: 'Yesterday', name: 'Yesterday' },
    { id: 'This week', name: 'This Week' },
    { id: 'Last week', name: 'Last Week' },
    { id: 'This month', name: 'This Month' },
    { id: 'Last month', name: 'Last Month' },
    { id: 'This Year', name: 'This Year' },
  ];
  selected: string = "This week";
  selectOption(id: string) {
    this.selected = id;
    
    
  this._alertsCards = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": "This week"
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority",
      "Tasks.status"
    ],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ]
  });

  this._queryVehicles = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      }
    ]
  });
this._queryDevice = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      }
    ]
  });
    this._queryPeople = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [],
        "filters": [
          {
            "member": "Tasks.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "Tasks.peopleid",
            "operator": "set",
            "values": []
          }
        ]
      });
    this.ngOnInit();
  }
  siteSelected: string = "All";
  selectOption1(id: string) {
    this.siteSelected = id;
    if( id == "All" ){
      this.selectOption(this.selected);
    }else{
      this._alertsCards = new BehaviorSubject({
          "measures": [
            "Tasks.count"
          ],
          "timeDimensions": [
            {
              "dimension": "Tasks.createdat",
              "dateRange": "This week"
            }
          ],
          "order": {
            "Tasks.count": "desc"
          },
          "dimensions": [
            "Tasks.priority",
            "Tasks.status"
          ],
          "filters": [
            {
              "member": "Tasks.tenantkey",
              "operator": "contains",
              "values": [
                this.href
              ]
            },
            {
              "member": "Tasks.site",
              "operator": "equals",
              "values": [
                id
              ]
            }
          ]
        });

  this._queryVehicles = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "vehicle"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          id
        ]
      }
    ]
  });
this._queryDevice = new BehaviorSubject( {
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": this.selected
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      },
      {
        "member": "Tasks.identifiertype",
        "operator": "equals",
        "values": [
          "device"
        ]
      },
      {
        "member": "Tasks.site",
        "operator": "equals",
        "values": [
          id
        ]
      }
    ]
  });
    this._queryPeople = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Tasks.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {},
        "dimensions": [],
        "filters": [
          {
            "member": "Tasks.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "Tasks.peopleid",
            "operator": "set",
            "values": []
          },
          {
            "member": "Tasks.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
    }
    this.ngOnInit();
    
  }
  
  public _alertsCards = new BehaviorSubject({
    "measures": [
      "Tasks.count"
    ],
    "timeDimensions": [
      {
        "dimension": "Tasks.createdat",
        "dateRange": "This week"
      }
    ],
    "order": {
      "Tasks.count": "desc"
    },
    "dimensions": [
      "Tasks.priority",
      "Tasks.status"
    ],
    "filters": [
      {
        "member": "Tasks.tenantkey",
        "operator": "contains",
        "values": [
          this.href
        ]
      }
    ]
  });
  public _queryVehicles = new BehaviorSubject( {
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "vehicle"
          ]
        }
      ]
    });
  public _queryDevice = new BehaviorSubject( {
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        }
      ]
    });
    public _queryPeople = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        }
      ]
    });

  public dataSource = [];
  public selectedSite;
  public newCubeJs;
  public bespokeLink;
  public queryVehicles;
  public queryDevice;
  public queryPeople;
  public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
  ngOnInit(): void {
      this.bespokeLink = "/bespoke";
    this.selectedSite = this.siteSelected;
    
    this._alertsCards.subscribe(alertsCards => {
      this.alertsCards = alertsCards;
    });
    this._queryVehicles.subscribe(queryVehicles => {
      this.queryVehicles = queryVehicles;
    });
    this._queryDevice.subscribe(queryDevice => {
      this.queryDevice = queryDevice;
    });
    this._queryPeople.subscribe(queryPeople => {
      this.queryPeople = queryPeople;
    });
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}

