import { Component, Input,Output,OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CubejsClient } from "@cubejs-client/ngx";


@Component({
  selector: 'app-insight-mobile',
  templateUrl: './insight-mobile.component.html',
  styleUrls: ['./insight-mobile.component.scss'],
  styles: ['html { background-color: #FFF !important; }html,body{background-color: #051f2c;}']
})
export class InsightMobileComponent implements OnInit {
    constructor(private meta: Meta, private title: Title, private router: Router, private cubejs: CubejsClient) {
      document.body.style.backgroundColor = "#051F2C";
      this.title.setTitle('Angular Dashboard with Material');
      this.meta.addTag({ name: 'description', content: 'How to build Angular Material Data Table with Cube.js' });
      this.meta.addTag({ name: 'keywords', content: 'Angular, Cube.js, Data Table, Table, Material UI' });
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // some code..
      }else{
        if ( window.location == window.parent.location ) {
          window.location.href = "/#/revoke"
        }
      }
    }
    public href: string = this.router.url.replace( "/", "" ).split("/")[1];
    public sorting = ['History.createdat', 'desc'];
    public startDate = "01/1/2019";
    public finishDate = "01/1/2022";
    dates: any[] = [
      { id: 'Today', name: 'Today' },
      { id: 'Yesterday', name: 'Yesterday' },
      { id: 'This week', name: 'This Week' },
      { id: 'Last week', name: 'Last Week' },
      { id: 'This month', name: 'This Month' },
      { id: 'Last month', name: 'Last Month' },
      { id: 'This Year', name: 'This Year' },
    ];
    selected: string = "Today";
    selectOption(id: string) {
      this.selected = id;
      this.ngOnInit();
    }
    
    siteSelected: string = "All";
    selectOption1(id: string) {
      this.siteSelected = id;
      this.ngOnInit();
      
    }

    reloadIframe(){
      window.parent.location.reload();
      window.opener.document.location.reload ()
    }
  public query5 = null;
    public _query5 = new BehaviorSubject({
      "measures": [],
      "timeDimensions": [
        {
          "dimension": "Layers.createddate"
        }
      ],
      "order": {},
      "filters": [
        {
          "member": "Layers.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Layers.category",
          "operator": "equals",
          "values": [
            "1"
          ]
        },
        {
          "member": "Layers.isdeleted",
          "operator": "equals",
          "values": [
            "false"
          ]
        }
      ],
      "dimensions": [
        "Layers.name"
      ]
    });
    public urlLink;
    public redirectLinkActivity;
    public redirectLinkAlerts;
    public redirectLinkVehicles;
    public redirectLinkDevices;
    public redirectLinkPeople;
    public redirectLinkBespoke;
    public redirectLinkPartners;
    public currentUrl;
    public bespokeLink;
    public acessBespoke;
    public newCubeJs;
    ngOnInit(): void {
      this.urlLink = this.router.url;
      
        this.redirectLinkActivity = "/activity-mobile";
        this.redirectLinkAlerts = "/alerts-mobile";
        this.redirectLinkVehicles = "/vehicles-mobile";
        this.redirectLinkDevices = "/devices-mobile";
        this.redirectLinkPeople = "/people-mobile";
        this.redirectLinkBespoke = "/bespoke-mobile";
        this.bespokeLink = "/bespoke-mobile";
        this.acessBespoke = "/bespoke-mobile";
        // this.currentUrl = "/insights-dashboard";
      
      if( this.siteSelected == "All" ){
        this._query5.subscribe(_query5 => {
          this.query5 = _query5;
          this.newCubeJs = new CubejsClient(
            {
              "token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDQ4MzY2MDd9.3qF6X7Tjq2tk-McSumVvlO60_YwVeUItGQwRyBdyrVM',
              "options": {
                  "apiUrl": 'https://reporting-management.advancedstudio.co.uk/cubejs-api/v1'
              }
          });
          this.newCubeJs.load(this.query5).subscribe(
            resultSet => {
              this.dataSource = resultSet.rawData();
            },
            err => console.log("HTTP Error", err)
          );
        });
      }
    }
    dataSource = [];
  }
  
