<!--<div class="table-warp" style="background-color: #242533;padding: 0px 10px 0 10px;position: fixed;top: 0;width: 100%;z-index: 1;">
  <div class="row narrow-row">
    <div class="col-12">
      <div class="insights-cards-wrapper">
        <div class="row narrow-row">
          <div class="col-2">
            <a [routerLink]="[redirectLinkActivity, href]">
              <div class="insights-card"> <i class="fas fa-exchange"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkAlerts, href]">
              <div class="insights-card"> <i class="fas fa-bell"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkVehicles, href]">
              <div class="insights-card"> <i class="fas fa-car"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkDevices, href]">
              <div class="insights-card active"> <i class="fas fa-laptop"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[redirectLinkPeople, href]">
              <div class="insights-card"> <i class="fas fa-user"></i>
              </div>
            </a>
          </div>
          <div class="col-2">
            <a [routerLink]="[acessBespoke, href]">
              <div class="insights-card"> <i class="fas fa-star"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<div class="component-header d-flex align-items-center justify-content-between">
  <h4><i class="fas fa-bell"></i>Device Dashboard</h4>
  <div class="date-Picker-wrap d-flex align-items-center ">
    <select class="date-Picker" (change)="selectOption($event.target.value)">
      <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
    </select>
    <select class="date-Picker" (change)="selectOption1($event.target.value)">
      <option value="All">All</option>
      <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
    </select>
  </div>
</div>

<!-- <div class="table-warp"> -->
  <div class="col-6" style="margin-top: 20px;float: left;">
    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Condition</div>
          <div class="cell-wrap text-right" style="width: 80px;">Count</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of deviceOnlineOffline">
          <div class="table-row">
            <div class="cell-wrap flex-fill">{{element['History.metadatacondition']}}</div>
            <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Source/Agent</div>
          <div class="cell-wrap text-right" style="width: 80px;">Count</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of sourceListing">
          <div class="table-row">
            <div class="cell-wrap flex-fill">Source</div>
            <div class="cell-wrap text-right" style="width: 80px;">{{element['Sources.count']}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Events</div>
          <div class="cell-wrap text-right" style="width: 80px;">Count</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of eventsListing">
          <div class="table-row">
            <div class="cell-wrap flex-fill">Events</div>
            <div class="cell-wrap text-right" style="width: 80px;">{{element['History.count']}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Agent Name</div>
        <div class="cell-wrap text-right">Device Count</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of deviceAPIData1">
          <div class="table-row">
            <div class="cell-wrap flex-fill">{{element['Agent_Name']}}</div>
            <div class="cell-wrap text-right">{{element['device_count']}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-6" style="margin-top: 20px;float: left;">
    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Make</div>
          <div class="cell-wrap text-right" style="width: 80px;">Count</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of deviceAPIData2">
          <div class="table-row">
            <div class="cell-wrap flex-fill">{{element['Device_vendor']}}</div>
            <div class="cell-wrap text-right" style="width: 80px;">{{element['device_count']}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Online/Offline Identifier</div>
          <div class="cell-wrap text-right" style="width: 80px;">Count</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of topDeviceListing">
          <div class="table-row">
            <div class="cell-wrap flex-fill">{{element['name'] ? element['name'] : element['newIdentifier']}}</div>
            <div class="cell-wrap text-right" style="width: 80px;">{{element['newCount']}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-table">
      <div class="table-header-row">
        <div class="cell-wrap flex-fill">Name</div>
        <div class="cell-wrap flex-fill">Identifier</div>
        <div class="cell-wrap flex-fill">IP</div>
        <div class="cell-wrap flex-fill">Created At</div>
      </div>
      <div class="table-inner-wrapper">
        <div class="table-row-wrapper" *ngFor="let element of deviceAPIData">
          <div class="table-row">
            <div class="cell-wrap flex-fill">{{element['Device_Name']}}</div>
            <div class="cell-wrap flex-fill">{{element['Device_Identifier']}}</div>
            <div class="cell-wrap flex-fill">{{element['Device_IP']}}</div>
            <div class="cell-wrap flex-fill">{{element['CreatedAt']}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- <app-devices-manufacturer [query]="queryVehicelsColour"></app-devices-manufacturer> -->
    
    
  <!-- </div> -->
  