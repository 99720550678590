<div class="component-header d-flex align-items-center justify-content-between">
    <h4><i class="fas fa-chart-pie-alt"></i>Graphs Dashboard</h4>
    <div class="date-Picker-wrap d-flex align-items-center ">
      <select class="date-Picker" (change)="selectOption($event.target.value)">
        <!-- <option value="Today">Date Range</option> -->
        <option [value]="date.id" *ngFor="let date of dates">{{date.name}}</option>
      </select>
      <select class="date-Picker" (change)="selectOption1($event.target.value)">
        <!-- <option value="All">Site</option> -->
        <option value="All">All</option>
        <option value="{{element['Layers.name']}}" [ngValue]="siteSelected" *ngFor="let element of dataSource">{{element['Layers.name']}}</option>
      </select>
    </div>
  </div>  
  
  <div class="grid-container">
    <div>
      <mat-grid-list [cols]="chartCols" rowHeight="175px" style="width:325px">
        <mat-grid-tile *ngFor="let card of cards" [colspan]="card.cols" [rowspan]="card.rows">
          <mat-card class="dashboard-card">
            <!-- <mat-card-header class="dashboard-card__header">
              <mat-card-title>
                <h3>{{card.title}}</h3>
              </mat-card-title>
            </mat-card-header> -->
            <!--<hr>-->
            <mat-card-content class="dashboard-card-content">
              <div>
                <!-- <app-graphs-chart [query]="card.query" *ngIf="card.chart === 'line'"></app-graphs-chart> -->
              </div>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  