import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import { BehaviorSubject } from "rxjs";
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeEn);

@Component({
  selector: 'app-graphs-chart',
  templateUrl: './graphs-chart.component.html',
  styleUrls: ['./graphs-chart.component.scss']
})
export class GraphsChartComponent implements OnInit {
  @Input() query: Object;
  constructor(private cubejs:CubejsClient){}

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 50,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: "#eeeeee",
      backgroundColor: "#ffffff",
      titleFontColor: "#43436B",
      bodyFontColor: "#A1A1B5",
      footerFontColor: "#A1A1B5",
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          display: false
        },
      ],
      yAxes: [
        {
          display: false
        },
      ],
    },
  };

  public _query4 = new BehaviorSubject({
    "measures": [
      "History.count"
    ],
    "timeDimensions": [
      {
        "dimension": "History.createdat",
        "dateRange": "This month"
      }
    ],
    "order": {
      "History.count": "desc"
    },
    "dimensions": [
      "History.vehiclefueltype",
      "History.vehicleeurostatus"
    ],
    "filters": [
      {
        "member": "History.tenantkey",
        "operator": "contains",
        "values": [
          "1624452064694"
        ]
      }
    ]
  });


  public barChartLabels = [];
  public barChartType = "line";
  public barChartLegend = true;
  public barChartData = [];
  public query4;
  ngOnInit() {
    this._query4.subscribe(query4 => {
      this.query4 = query4;
    });
    this.cubejs.load(this.query).subscribe(
      resultSet => {
        this.barChartLabels = resultSet.chartPivot().map((c) => formatDate(c.category, 'longDate', 'en'));
        this.barChartData = resultSet.series().map((s, index) => ({
          label: s.title,
          data: [100, 110, 12, 223, 324, 125, 216, 27, 128, 8, 9, 0],
          fill: false,
        }));
      },
      err => console.log('HTTP Error', err)
    );
  }
}
