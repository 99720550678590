import { Component, OnInit, Input } from "@angular/core";
import { CubejsClient } from '@cubejs-client/ngx';
import {formatDate, registerLocaleData} from "@angular/common"
import localeEn from '@angular/common/locales/en';
import { getRelativePosition } from 'chart.js/helpers';
import { Chart } from 'chart.js';
import * as annotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-euro-fuel-type4-doughnut',
  templateUrl: './euro-fuel-type4-doughnut.component.html',
  styleUrls: ['./euro-fuel-type4-doughnut.component.scss']
})
export class EuroFuelType6DoughnutComponent implements OnInit {
  constructor(private cubejs:CubejsClient){}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  @Input() set query(query: object) {
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = [];
        this.dataSource = resultSet.tablePivot();
        for(var index in this.dataSource)
        { 
            if(this.dataSource[index]['History.vehicleeurostatus'].replace(/[^0-9]/g, '') >= "4"){
              this.euro4Count = this.euro4Count + this.dataSource[index]['History.count'];
              // this.euro4.push(  );
            }else{
              this.noneEuro4Count = this.noneEuro4Count + this.dataSource[index]['History.count'];
            }
        }
        this.noneEuro4.push( this.euro4Count,this.noneEuro4Count );
        this.data = {
          labels: ['Euro 4+', "< Euro 4"],
          datasets: [{
          label: 'Euro 4',
          data: this.noneEuro4,
          backgroundColor: ['#81c784', '#e57373'],
          borderWidth: 1,
          barPercentage: 0.5,
          }]
          }
        this.canvas = document.getElementById('euro4-doughnut');
        this.ctx = this.canvas.getContext('2d');
        const myChart = new Chart(this.ctx, {
          type: 'doughnut',
          data: this.data,
          options: {
            maintainAspectRatio : false,
            responsive: true,
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  usePointStyle: true,
                  padding: 10,
                  boxWidth: 40
                },
                
              },
              title: {
                display: false,
                text: 'Volume% by Fuel Type Petrol'
              }
            },
          },
        });
      });
  }
  ngOnInit() {
    
  }
}
