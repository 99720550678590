import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import Html from "../views/bespoke-fuel.html"; 
@Component({
  selector: 'app-bespoke-fuel',
  templateUrl: './bespoke-fuel.component.html',
  styleUrls: ['./bespoke-fuel.component.scss'],
  template: "../../views/bespoke-fuel.html"
})
export class BespokeFuelComponent implements OnInit {
  
  // test1 = require
  // test = require( "../../views/bespoke-fuel.html" );
  public href: string = this.router.url.replace( "/", "" ).split("/")[1];
  public apiUrl = "https://api.advancedstudio.co.uk/features/getAllOrganizations?ID=" + this.href;
  public report;
  public emissions;
  constructor(private router: Router) {
    document.body.style.backgroundColor = "#051F2C";
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // some code..
    }else{
      if ( window.location == window.parent.location ) {
        window.location.href = "/#/revoke"
      }
    }
    const xhttp = new XMLHttpRequest();
    var that = this;
    xhttp.onload = function() {
      if(this.status != 200){
        window.location.href = "/#/revoke";
      }
      let response = JSON.parse( this.response);
      if(response.success == 0){
        window.location.href = "/#/revoke";
      }else{
        that.repeat(response.data.emissions, response.data.report);
      }
    }
    console.log(that.report);
    xhttp.open("GET", this.apiUrl);
    xhttp.send();

    xhttp.onerror = function(){
      window.location.href = "/#/revoke";
    }
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // some code..
    }else{
      if ( window.location == window.parent.location ) {
        window.location.href = "/#/revoke"
      }
    }
  }

  repeat(emissions, report){
    this.emissions = emissions;
    this.report = report;
    setInterval(() => {
      this.ngOnInit();
    }, 30000);
  }
   public bespokeLink;
   public currentHref: string = this.router.url.replace( "/", "" ).split("/")[0];
   public urlLink;
   public redirectLinkActivity;
   public redirectLinkAlerts;
   public redirectLinkVehicles;
   public redirectLinkDevices;
   public redirectLinkPeople;
   public redirectLinkBespoke;
   public redirectLinkPartners;
   public acessBespoke;
   public fuelType
  ngOnInit(): void {
    this.urlLink = this.router.url;
      this.access = true;
      this.denied = false;
    
      this.redirectLinkActivity = "/activity-mobile";
      this.redirectLinkAlerts = "/alerts-mobile";
      this.redirectLinkVehicles = "/vehicles-mobile";
      this.redirectLinkDevices = "/devices-mobile";
      this.redirectLinkPeople = "/people-mobile";
      this.redirectLinkBespoke = "/bespoke-mobile";
      this.bespokeLink = "/bespoke-mobile";
      this.acessBespoke = "/bespoke-mobile";
      this.fuelType = "/fuel-type-mobile";
      // this.currentUrl = "/insights-dashboard";
    
  }
  access = false;
  denied = false;
}

