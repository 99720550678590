import { Component, Input, Output, OnInit  } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-vehicles-euro-status',
  templateUrl: './vehicles-euro-status.component.html',
  styleUrls: ['./vehicles-euro-status.component.scss']
})
export class VehiclesEuroStatusComponent {
  constructor(private cubejs: CubejsClient) {}
  @Input() set query(query: object) {
    this.loading = true;
    this.cubejs.load(query).subscribe(
      resultSet => {
        this.dataSource = resultSet.tablePivot();
        this.newCount = 0;
        this.count = 0;
        
        for( var i=0; i<this.dataSource.length;i++ ){
          var ndata = this.dataSource[i];
          if( this.dataSource[i]['History.vehicleeurostatus'] == null || this.dataSource[i]['History.vehicleeurostatus'] == ""){
            this.count = ndata['History.count'] + this.count;
            this.dataSource = this.dataSource.filter(item => item !== ndata);
          }
        }
        
        for( var l=0; l<this.dataSource.length;l++ ){
          if( this.dataSource[l]['History.vehicleeurostatus'] != "" || this.dataSource[l]['History.vehicleeurostatus'] != null )
            if( this.dataSource[l]['History.vehicleeurostatus'].replace(/[^0-9]/g, '') == 6){
              for( var i=0; i<this.dataSource.length;i++ ){
                var ndata = this.dataSource[i];
                if( this.dataSource[i]['History.vehicleeurostatus'] != "" || this.dataSource[i]['History.vehicleeurostatus'] != null )
                  if(this.dataSource[i]['History.vehicleeurostatus'].replace(/[^0-9]/g, '') == 6){
                    this.newCount = ndata['History.count'] + this.newCount;
                    this.includeCount = true;
                    this.dataSource = this.dataSource.filter(item => item !== ndata);
                  }
              }
            }
        }

        for( var l=0; l<this.dataSource.length;l++ ){
          if( this.dataSource[l]['History.vehicleeurostatus'] != "" || this.dataSource[l]['History.vehicleeurostatus'] != null )
            if( this.dataSource[l]['History.vehicleeurostatus'].replace(/[^0-9]/g, '') == 6){
              for( var i=0; i<this.dataSource.length;i++ ){
                var ndata = this.dataSource[i];
                if( this.dataSource[i]['History.vehicleeurostatus'] != "" || this.dataSource[i]['History.vehicleeurostatus'] != null )
                  if(this.dataSource[i]['History.vehicleeurostatus'].replace(/[^0-9]/g, '') == 6){
                    this.newCount = ndata['History.count'] + this.newCount;
                    this.includeCount = true;
                    this.dataSource = this.dataSource.filter(item => item !== ndata);
                  }
              }
            }
        }

        if( this.count > 0){
          this.dataSource.push({ "History.vehicleeurostatus": "OTHER/UNKNOWN", 'History.count': this.count});
        }

        if( this.newCount > 0){
          this.dataSource.push({ "History.vehicleeurostatus": "Euro 6", 'History.count': this.newCount});
        }

       this.dataSource.sort(function(a, b) {
            var textA = a['History.vehicleeurostatus'].toUpperCase();
            var textB = b['History.vehicleeurostatus'].toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        
        this.loading = false;
      },
      err => console.log("HTTP Error", err)
    );
  };
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = false;
  length = 0;
  pageSize = 10;
  count = 0;
  includeCount = false;
  unknown = false;
  newCount = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
