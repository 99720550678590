import { Component, Input, Output, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-euro-fuel-type6',
  templateUrl: './euro-fuel-type6.component.html',
  styleUrls: ['./euro-fuel-type6.component.scss']
})
export class EuroFuelType6Component {

  constructor(private cubejs: CubejsClient) {}
  dataSource = [];
  euro4Count = 0;
  euro4 = [];
  noneEuro4Count = 0;
  noneEuro4 = [];
  canvas: any;
  ctx: any;
  data;
  id;
  neweuro6Count = 0;
  newNoneEuro6Count = 0;
  total;
  @Input() set upToEuro6Count(upToEuro6Count: object) {
    this.newNoneEuro6Count = Number(upToEuro6Count);
    this.ngOnInit();
  }

  @Input() set euro6Plus(euro6Plus: object) {
    this.neweuro6Count = Number(euro6Plus);
    this.ngOnInit();
  }

  @Input() set query(query: object) {
    this.query = query;
  }

  ngOnInit() {
        this.euro4Count = 0;
        this.euro4 = [];
        this.noneEuro4 = [];
        this.total = this.neweuro6Count + this.newNoneEuro6Count;
        this.euro4Count = (this.neweuro6Count/this.total)*100;
        this.noneEuro4Count = (this.newNoneEuro6Count/this.total)*100;
        var lables = [this.euro4Count + "%", this.noneEuro4Count + "%"]
        this.euro4.push( this.euro4Count );
        this.noneEuro4.push( this.noneEuro4Count );
        if( this.noneEuro4.length > 0 ){
          this.loading = false;
        }
        
        this.data = {
          labels: [''],
          usePointStyle: true,
          datasets: [
          {
            label: 'Euro 6-',
            data: this.noneEuro4,
            backgroundColor: ['#b9c9d6'],
            borderWidth: 1
            },{
          label: 'Euro 6+',
          data: this.euro4,
          backgroundColor: ['#51789a'],
          borderWidth: 1
          }]
          }
          if( this.myChart ){
            this.myChart.destroy();
          }
        this.canvas = document.getElementById('euro6');
        this.ctx = this.canvas.getContext('2d');
        this.myChart = new Chart(this.ctx, {
          type: 'bar',
          data: this.data,
          options: {
            indexAxis: 'y',
            elements: {
              bar: {
                borderWidth: 3,
              }
            },
            responsive: true,
            scales: {
              x: {
                stacked: true,
                ticks:{
                  precision: 0,
                }
              },
              y: {
                stacked: true,
              }
            },
            plugins: {tooltip: {
              enabled: true,
              callbacks: {
                label: function(context) {
                    var data = context.dataset.data[0];
                    var label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }
                    if (context.parsed.y !== null) {
                        label += data.toFixed(2);
                    }
                    return label+"%";
                },
              }
            },
              legend: {
                position: 'top',
                align: "end",
                labels: {
                  usePointStyle: true,
                  color: 'white'
                },
              },
              title: {
                display: false,
                position: 'top',
                text: 'Diesel'
              },
            }
          },
        });
        if( this.myChart )
          this.myChart.update();
}
  myChart;
  loading = true;
  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }
}
