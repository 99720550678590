
import { Component, Input, Output, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-insight-vehicle',
  templateUrl: './insight-vehicle.component.html',
  styleUrls: ['./insight-vehicle.component.scss']
})
export class InsightVehicleComponent {
  constructor(private cubejs: CubejsClient) {}
  selected: string;
  @Input() href: string
  
  nData = [];
  public VehiclesAlertsTotal = null;
  public _VehiclesAlertsTotal = null;
  public VehiclesActivityTotal = null;
  public _VehiclesActivityTotal = null;
  public VehiclesAlertsExitTotal = null;
  public _VehiclesAlertsExitTotal = null;
  public VehiclesActivityExitTotal = null;
  public _VehiclesActivityExitTotal = null;
  public DeviceAlertsEnterTotal = null;
  public _DeviceAlertsEnterTotal = null;
  public DeviceActivityEnterTotal = null;
  public _DeviceActivityEnterTotal = null;
  public DeviceAlertsExitTotal = null;
  public _DeviceAlertsExitTotal = null;
  public DeviceActivityExitTotal = null;
  public _DeviceActivityExitTotal = null;
  public PeopleAlertsEnterTotal = null;
  public _PeopleAlertsEnterTotal = null;
  public PeopleActivityEnterTotal = null;
  public _PeopleActivityEnterTotal = null;
  public PeopleAlertsExitTotal = null;
  public _PeopleAlertsExitTotal = null;
  public PeopleActivityExitTotal = null;
  public _PeopleActivityExitTotal = null;

  public VehiclesAlertsTotalCount = 0;
  public VehiclesActivityTotalCount = 0;
  public VehiclesAlertsExitTotalCount = 0;
  public VehiclesActivityExitTotalCount = 0;
  public DeviceAlertsEnterTotalCount = 0;
  public DeviceActivityEnterTotalCount = 0;
  public DeviceAltersExitTotalCount = 0;
  public DeviceActivityExitTotalCount = 0;
  public PeopleAlertsEnterTotalCount = 0;
  public PeopleActivityEnterTotalCount = 0;
  public PeopleAltersExitTotalCount = 0;
  public PeopleActivityExitTotalCount = 0;

  public data = true;
  @Input() set dateRange( dateRange: string ){
    this.selected= dateRange;
    this.loading = false;
    this.dataSource = [];
    this.data = false;
    this.nonSelectedSiteFunction();
  }
  @Input() set selectedSite( selectedSite: string){
    this.dataSource = [];
    if(selectedSite == "All" && this.selected != "Today"){
      this.nonSelectedSiteFunction();
    }else{
      if( selectedSite != "All" )
        this.SelectedSiteFunction(selectedSite);
    }
  }
  SelectedSiteFunction(selectedSite){
    let id = selectedSite;
    this.VehiclesAlertsTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension":"Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.identifiertype",
        "Tasks.sourcetype"
      ],
      "filters": [
        {
          "member": "Tasks.identifiertype",
          "operator": "contains",
          "values": [
            "vehicle"
          ]
        },
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member":"Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "enter","exit"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
  this.VehiclesAlertsTotal.subscribe(querySite => {
    this._VehiclesAlertsTotal = querySite;
    this.cubejs.load(this._VehiclesAlertsTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          if(this.nData[i]['Tasks.identifiertype'] == "Vehicle" && this.nData[i]['Tasks.sourcetype'] == "Enter")
            this.VehiclesAlertsTotalCount = this.nData[i]["Tasks.count"];

          if(this.nData[i]['Tasks.identifiertype'] == "Vehicle" && this.nData[i]['Tasks.sourcetype'] == "Exit")
            this.VehiclesAlertsExitTotalCount = this.nData[i]["Tasks.count"];
        }
        this.dataSource = this.dataSource.map(function(e) {
          return e;
        });
      },
      err => console.log("HTTP Error", err)
    );
  });

    this.VehiclesActivityTotal = new BehaviorSubject({  
      "measures": [ "History.count" ],
      "timeDimensions": [
        {
          "dimension":"History.createdat",
          "dateRange": this.selected
        }
      ],
      "dimensions": [
         "History.identifiertype",
         "History.metadatacondition"
        ],
        "order": {
          "History.count": "desc"
        },
        "filters": [
          {
            "member": "History.metadatacondition",
            "operator":"contains",
            "values": [
              "enter",
              "exit",
              "online",
              "offline"
            ]
          },
          {
            "member": "History.identifiertype",
            "operator": "contains",
            "values": [
              "vehicle",
              "device"
            ]
          },
          {
            "member": "History.tenantkey",
            "operator": "contains",
            "values": [
              this.href
            ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
      this.VehiclesActivityTotal.subscribe(querySite => {
        this._VehiclesActivityTotal = querySite;
        this.cubejs.load(this._VehiclesActivityTotal).subscribe(
          resultSet => {
            this.nData = resultSet.rawData();
            
            for( var i = 0; i < this.nData.length; i++ ){
              if(this.nData[i]['History.metadatacondition'] == "enter" && this.nData[i]['History.identifiertype'] == "Vehicle")
                this.VehiclesActivityTotalCount = this.nData[i]["History.count"];

              if(this.nData[i]['History.metadatacondition'] == "exit" && this.nData[i]['History.identifiertype'] == "Vehicle")
                this.VehiclesActivityExitTotalCount = this.nData[i]["History.count"];

              if(this.nData[i]['History.metadatacondition'] == "offline" && this.nData[i]['History.identifiertype'] == "Device")
                this.DeviceActivityExitTotalCount = this.nData[i]["History.count"];

              if(this.nData[i]['History.metadatacondition'] == "online" && this.nData[i]['History.identifiertype'] == "Device")
                this.DeviceActivityEnterTotalCount = this.nData[i]["History.count"];
            }
            this.dataSource = this.dataSource.map(function(e) {
              return e;
            });
          },
          err => console.log("HTTP Error", err)
        );
      });

    this.DeviceAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "online"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.DeviceAlertsEnterTotal.subscribe(querySite => {
      this._DeviceAlertsEnterTotal = querySite;
      this.cubejs.load(this._DeviceAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    this.DeviceAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "offline"
          ]
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.DeviceAlertsExitTotal.subscribe(querySite => {
      this._DeviceAlertsExitTotal = querySite;
      this.cubejs.load(this._DeviceAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    
    this.PeopleAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.sourcetype"
      ],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.peopleid",
          "operator": "set"
        },
        {
          "member": "Tasks.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.PeopleAlertsEnterTotal.subscribe(querySite => {
      this._PeopleAlertsEnterTotal = querySite;
      this.cubejs.load(this._PeopleAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            if(this.nData[i]['Tasks.sourcetype'] == "Enter")
              this.PeopleAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
            if(this.nData[i]['Tasks.sourcetype'] == "Exit")
              this.PeopleAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });
    
    this.PeopleActivityEnterTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "History.count": "desc"
      },
      "dimensions": [
        "History.metadatacondition"
      ],
      "filters": [
        {
          "member": "History.metadatacondition",
          "operator": "contains",
          "values": [
            "enter",
            "exit"
          ]
        },
        {
          "member": "History.peopleid",
          "operator":"notContains",
          "values": [
            "00000"
          ]
        },
        {
          "member":"History.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
          },
          {
            "member": "History.site",
            "operator": "equals",
            "values": [
              id
            ]
          }
        ]
      });
  this.PeopleActivityEnterTotal.subscribe(querySite => {
    this._PeopleActivityEnterTotal = querySite;
    this.cubejs.load(this._PeopleActivityEnterTotal).subscribe(
      resultSet => {
        this.nData = resultSet.rawData();
        for( var i = 0; i < this.nData.length; i++ ){
          if( this.nData[i]['History.metadatacondition'] == "enter" )
            this.PeopleActivityEnterTotalCount = this.nData[i]["History.count"];

          if( this.nData[i]['History.metadatacondition'] == "exit" )
            this.PeopleActivityExitTotalCount = this.nData[i]["History.count"];
        }
        this.dataSource = this.dataSource.map(function(e) {
          return e;
        });
      },
      err => console.log("HTTP Error", err)
    );
  });

    this.PeopleActivityExitTotal = new BehaviorSubject({
      "measures": [
        "History.count"
      ],
      "timeDimensions": [
        {
          "dimension": "History.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "History.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "History.peopleid",
          "operator": "notContains",
          "values": [
            "00000"
          ]
        },
        {
          "member": "History.triggercondition",
          "operator": "equals",
          "values": [
            "exit"
          ]
        },
        {
          "member": "History.site",
          "operator": "equals",
          "values": [
            id
          ]
        }
      ]
    });
    this.PeopleActivityExitTotal.subscribe(querySite => {
      this._PeopleActivityExitTotal = querySite;
      this.cubejs.load(this._PeopleActivityExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });











  }
  nonSelectedSiteFunction(){
    this.VehiclesAlertsTotal = new BehaviorSubject({
        "measures": [
          "Tasks.count"
        ],
        "timeDimensions": [
          {
            "dimension":"Tasks.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "Tasks.count": "desc"
        },
        "dimensions": [
          "Tasks.identifiertype",
          "Tasks.sourcetype"
        ],
        "filters": [
          {
            "member": "Tasks.identifiertype",
            "operator": "contains",
            "values": [
              "vehicle"
            ]
          },
          {
            "member": "Tasks.tenantkey",
            "operator": "equals",
            "values": [
              this.href
            ]
          },
          {
            "member":"Tasks.sourcetype",
            "operator": "contains",
            "values": [
              "enter","exit"
            ]
          }
        ]
      });
    this.VehiclesAlertsTotal.subscribe(querySite => {
      this._VehiclesAlertsTotal = querySite;
      this.cubejs.load(this._VehiclesAlertsTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            if(this.nData[i]['Tasks.identifiertype'] == "Vehicle" && this.nData[i]['Tasks.sourcetype'] == "Enter")
              this.VehiclesAlertsTotalCount = this.nData[i]["Tasks.count"];

            if(this.nData[i]['Tasks.identifiertype'] == "Vehicle" && this.nData[i]['Tasks.sourcetype'] == "Exit")
              this.VehiclesAlertsExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.VehiclesActivityTotal = new BehaviorSubject({  
        "measures": [ "History.count" ],
        "timeDimensions": [
          {
            "dimension":"History.createdat",
            "dateRange": this.selected
          }
        ],
        "dimensions": [
           "History.identifiertype",
           "History.metadatacondition"
          ],
          "order": {
            "History.count": "desc"
          },
          "filters": [
            {
              "member": "History.metadatacondition",
              "operator":"contains",
              "values": [
                "enter",
                "exit",
                "online",
                "offline"
              ]
            },
            {
              "member": "History.identifiertype",
              "operator": "contains",
              "values": [
                "vehicle",
                "device"
              ]
            },
            {
              "member": "History.tenantkey",
              "operator": "contains",
              "values": [
                this.href
              ]
            }
          ]
        });
    this.VehiclesActivityTotal.subscribe(querySite => {
      this._VehiclesActivityTotal = querySite;
      this.cubejs.load(this._VehiclesActivityTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          
          for( var i = 0; i < this.nData.length; i++ ){
            if(this.nData[i]['History.metadatacondition'] == "enter" && this.nData[i]['History.identifiertype'] == "Vehicle")
              this.VehiclesActivityTotalCount = this.nData[i]["History.count"];

            if(this.nData[i]['History.metadatacondition'] == "exit" && this.nData[i]['History.identifiertype'] == "Vehicle")
              this.VehiclesActivityExitTotalCount = this.nData[i]["History.count"];

            if(this.nData[i]['History.metadatacondition'] == "offline" && this.nData[i]['History.identifiertype'] == "Device")
              this.DeviceActivityExitTotalCount = this.nData[i]["History.count"];

            if(this.nData[i]['History.metadatacondition'] == "online" && this.nData[i]['History.identifiertype'] == "Device")
              this.DeviceActivityEnterTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.DeviceAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "online"
          ]
        }
      ]
    });
    this.DeviceAlertsEnterTotal.subscribe(querySite => {
      this._DeviceAlertsEnterTotal = querySite;
      this.cubejs.load(this._DeviceAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.DeviceAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "contains",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.identifiertype",
          "operator": "equals",
          "values": [
            "device"
          ]
        },
        {
          "member": "Tasks.metadata",
          "operator": "contains",
          "values": [
            "offline"
          ]
        }
      ]
    });
    this.DeviceAlertsExitTotal.subscribe(querySite => {
      this._DeviceAlertsExitTotal = querySite;
      this.cubejs.load(this._DeviceAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.DeviceAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleAlertsEnterTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {
        "Tasks.count": "desc"
      },
      "dimensions": [
        "Tasks.sourcetype"
      ],
      "filters": [
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.peopleid",
          "operator": "set"
        }
      ]
    });
    this.PeopleAlertsEnterTotal.subscribe(querySite => {
      this._PeopleAlertsEnterTotal = querySite;
      this.cubejs.load(this._PeopleAlertsEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            if(this.nData[i]['Tasks.sourcetype'] == "Enter")
              this.PeopleAlertsEnterTotalCount = this.nData[i]["Tasks.count"];
            if(this.nData[i]['Tasks.sourcetype'] == "Exit")
              this.PeopleAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleAlertsExitTotal = new BehaviorSubject({
      "measures": [
        "Tasks.count"
      ],
      "timeDimensions": [
        {
          "dimension": "Tasks.createdat",
          "dateRange": this.selected
        }
      ],
      "order": {},
      "dimensions": [],
      "filters": [
        {
          "member": "Tasks.peopleid",
          "operator": "set",
          "values": []
        },
        {
          "member": "Tasks.tenantkey",
          "operator": "equals",
          "values": [
            this.href
          ]
        },
        {
          "member": "Tasks.sourcetype",
          "operator": "contains",
          "values": [
            "exit"
          ]
        }
      ]
    });
    this.PeopleAlertsExitTotal.subscribe(querySite => {
      this._PeopleAlertsExitTotal = querySite;
      this.cubejs.load(this._PeopleAlertsExitTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            this.PeopleAltersExitTotalCount = this.nData[i]["Tasks.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

    this.PeopleActivityEnterTotal = new BehaviorSubject({
        "measures": [
          "History.count"
        ],
        "timeDimensions": [
          {
            "dimension": "History.createdat",
            "dateRange": this.selected
          }
        ],
        "order": {
          "History.count": "desc"
        },
        "dimensions": [
          "History.metadatacondition"
        ],
        "filters": [
          {
            "member": "History.metadatacondition",
            "operator": "contains",
            "values": [
              "enter",
              "exit"
            ]
          },
          {
            "member": "History.peopleid",
            "operator":"notContains",
            "values": [
              "00000"
            ]
          },
          {
            "member":"History.tenantkey",
            "operator": "equals",
            "values": [
              this.href
            ]
            }
          ]
        });
    this.PeopleActivityEnterTotal.subscribe(querySite => {
      this._PeopleActivityEnterTotal = querySite;
      this.cubejs.load(this._PeopleActivityEnterTotal).subscribe(
        resultSet => {
          this.nData = resultSet.rawData();
          for( var i = 0; i < this.nData.length; i++ ){
            if( this.nData[i]['History.metadatacondition'] == "enter" )
              this.PeopleActivityEnterTotalCount = this.nData[i]["History.count"];

            if( this.nData[i]['History.metadatacondition'] == "exit" )
              this.PeopleActivityExitTotalCount = this.nData[i]["History.count"];
          }
          this.dataSource = this.dataSource.map(function(e) {
            return e;
          });
        },
        err => console.log("HTTP Error", err)
      );
    });

  }
  @Input() limit: number;
  @Output() pageEvent = new EventEmitter();
  @Output() sortingChanged = new EventEmitter();
  loading = true;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  dataSource = [];
  displayedColumns = ['Site', 'Count'];
  changeSorting(value) {
    this.sortingChanged.emit(value)
  }
}
